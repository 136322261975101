import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";

import Chit from "../components/Chit.js";
import { getCustomerId } from "../utility/HeaderConfig";
import { Link } from "react-router-dom";
import { getCustomer } from "api/CustomerApi";
import MyProfile from "./MyProfile.js";

const value = [
  {
    icon1: <i className="bi bi-check-circle" tw="text-4xl text-white"></i>,
    icon: <i className="bi bi-check-circle icon-bg"></i>,
    name: "Active",
    count: "2",
    click: "Active",
  },
  {
    icon1: <i className="bi bi-hourglass-top" tw="text-4xl text-white"></i>,
    icon: <i className="bi bi-hourglass-top icon-bg"></i>,
    name: "Pending",
    count: "3",
    click: "Waiting_For_Approval",
  },
  {
    icon1: <i className="bi bi-door-closed " tw="text-4xl text-white"></i>,
    icon: <i className="bi bi-door-closed icon-bg"></i>,
    name: "Close",
    count: "1",
    click: "Completed",
  },
  {
    icon1: <i className="bi bi-x-circle icon-bg" tw="text-4xl text-white"></i>,
    icon: <i className="bi bi-x-circle icon-bg"></i>,
    name: "Cancelled",
    count: "6",
    click: "Cancelled",
  },
];

const TabControl = styled.div`
  ${tw`bg-custom-green rounded p-6  cursor-pointer`}
  &:hover {
    ${tw`bg-custom-100 text-white`}
  }
  ${(props) => props.active && tw`bg-custom-100! text-gray-100!`}
  }
`;

export default () => {
  const [Details, setDetails] = useState({});
  const [Status, setStatus] = useState("Active");

  useEffect(() => {
    getCustomerDetails();
  }, []);

  const getCustomerDetails = () => {
    const response = getCustomer(getCustomerId());
    response.then((data) => {
      setDetails({
        name: data.response.name,
        mobile: data.response.mobile,
        email: data.response.email,
        address1:
          data.response.customerAddress != null
            ? data.response.customerAddress.address1
            : "",
        branchName:
          data.response.branchName != null ? data.response.branchName : "-",
          branchId : data.response.branchId,
          customerId : data.response.id
      });
    });
  };

  return (
    <div className="row m-0 position-relative">
      <MyProfile Details={Details} />
      <div className="col-lg-12 col-xl-9 py-4 px-0" tw="bg-gray-100">
        <div className="container-fluid ">
          <h4 tw="font-dancing">Dashboard</h4>
          <div className="ptSans" tw="text-sm">
            <Link to="/MainLandingPage" tw="ml-3 no-underline text-black">
              Home
            </Link>
            / <span tw="ml-3">MyScheme</span> /
            <span tw="ml-3 text-custom-100">Dashboard</span>
          </div>

          <div className="row my-3">
            {value.map((data, idx) => (
              <div className="col-lg-3 col-md-6 py-2">
                <div
                  className="card o-hidden border-0 overflow-hidden"
                  onClick={() => setStatus(data.click)}
                >
                  <TabControl
                    className="card-body"
                    key={idx}
                    active={Status === data.click}
                  >
                    <div className="media static-top-widget d-flex justify-content-around text-center">
                      <div className="align-self-center text-center">
                        {data.icon1}
                      </div>
                      <div className="media-body pl-3 text-white">
                        <h4
                          className="mb-1 ptSans text-uppercase"
                          tw="text-xl font-semibold tracking-widest"
                        >
                          {data.name}
                        </h4>
                        {/* <span tw="text-sm">{data.count}</span> */}
                        {data.icon}
                      </div>
                    </div>
                  </TabControl>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Chit chitStatus={Status} Details={Details} />
      </div>
    </div>
  );
};
