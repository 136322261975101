import axios from "axios";
import { BASE_URL } from '../utility/UrlConstants';
import { getHeaderConfig } from '../utility/HeaderConfig';


const  getAllProductSubCate = ( searchValue: Object) => {
    return axios
      .get(`${BASE_URL}/products/subcategory/metadata/get-all?metaProdSubCatFilter=${JSON.stringify(
        searchValue
      )}`, getHeaderConfig())
      .then((dataList) => {
        const respData = {
          responseCode: dataList.status,
          response: dataList.data,
        };
        return respData;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
  
  
export { getAllProductSubCate };
