import axios from "axios";
import { BASE_URL } from '../utility/UrlConstants';
import { getHeaderConfig , getBearerToken} from '../utility/HeaderConfig';

  const productLikeCount = (filters: String) => {
    return axios
      .get(`${BASE_URL}/products-likes/cust-product-likes-count?${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  const productWishCount = (filters: String) => {
    return axios
      .get(`${BASE_URL}/customer-wishlist/get-by-customer-and-pdt?${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };
  

  const deleteCustomerLike = (filters: String) => {
    return axios
      .delete(`${BASE_URL}/products-likes/delete?${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  const createCustomerLike = (filters: String) => {
    axios.defaults.headers.common.token = `Bearer ${ getBearerToken()}`;
    return axios
      .post(`${BASE_URL}/products-likes/create?${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };


  const createCustomerWish = (filters: String) => {
    axios.defaults.headers.common.token = `Bearer ${ getBearerToken()}`;
    return axios
      .post(`${BASE_URL}/customer-wishlist/create?${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  const deleteCustomerWish = (filters: String) => {
    return axios
      .delete(`${BASE_URL}/customer-wishlist/delete?${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };




  export { productLikeCount , productWishCount , deleteCustomerLike , createCustomerLike , createCustomerWish , deleteCustomerWish };