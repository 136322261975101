import React, { memo } from "react";

function CheckoutProgressBar({ address, payment }) {
  return (
    <div className="row">
      <div className="container">
        <div className="checkout-wrap p-2 p-sm-4">
          <ul
            id="checkout-bar"
            className={`${address && "step1"} ${payment && "step2"} `}
          >
            <li className="step1">
              <span tw="text-xs sm:text-base">SHIPPING ADDRESS</span>
            </li>
            <li className="step2">
              <span tw="text-xs sm:text-base">PAYMENT DETAILS</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default memo(CheckoutProgressBar);