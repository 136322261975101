import tw from "twin.macro";
// import { ReactComponent as Heart } from "images/star-icon.svg";
// import { ReactComponent as Hand } from "images/hand.svg";
import { getCustomerId } from "../utility/HeaderConfig";
import {
  productLikeCount,
  productWishCount,
  deleteCustomerLike,
  createCustomerLike,
  createCustomerWish,
  deleteCustomerWish,
} from "api/Like&WishCountApi";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useCartContext } from "context/UseCartContext";

// const CardRatingContainer = tw.div`cursor-pointer ml-4 `;
const CardRating = styled.div`
  ${tw` text-sm font-normal flex items-center justify-center  text-base text-gray-600 cursor-pointer rounded-full p-2 border-2 border-gray-400 border-dotted  bg-white`}
`;

export default (props) => {
  const [Like, setLike] = useState(false);
  const [Wish, setWish] = useState(false);
  const [LikeCount, setLikeCount] = useState(props.categoryItem.likesCount);
  const [WishCount, setWishCount] = useState(props.categoryItem.wishListCount);

  const { dispatch } = useCartContext();

  useEffect(() => {
    const productCustomerLike = () => {
      if (
        getCustomerId() !== "onlinepayment" &&
        getCustomerId() !== "" &&
        getCustomerId() !== null &&
        getCustomerId() !== undefined
      ) {
        const likeWishFilter = `customerId=${getCustomerId()}&productId=${
          props.common && props.categoryItem.productId
            ? props.categoryItem.productId
            : props.categoryItem.id
        }`;
        productLikeCount(likeWishFilter).then((data) => {
          if (data.response > 0) {
            setLike(true);
          }
        });
        productWishCount(likeWishFilter).then((item) => {
          if (item.response.length > 0) {
            setWish(true);
          }
        });
      }
    };
    productCustomerLike();
    //eslint-disable-next-line
  }, [props.categoryItem.id]);

  const wishCountFunc = () => {
    if (
      getCustomerId() !== "onlinepayment" &&
      getCustomerId() !== "" &&
      getCustomerId() !== null &&
      getCustomerId() !== undefined
    ) {
      if (Wish) {
        setWish(false);
        setWishCount(WishCount - 1);
        const deletWish = `customerId=${getCustomerId()}&productId=${
          props.common && props.categoryItem.productId
            ? props.categoryItem.productId
            : props.categoryItem.id
        }`;
        deleteCustomerWish(deletWish);
      } else {
        setWish(true);
        setWishCount(WishCount + 1);
        const createWish = `customerId=${getCustomerId()}&productId=${
          props.common && props.categoryItem.productId
            ? props.categoryItem.productId
            : props.categoryItem.id
        }&remark=${"liked"}`;
        createCustomerWish(createWish);
      }
    } else {
      dispatch({ type: "MODAL", payload: true });
    }
  };

  const likeCountFunc = () => {
    if (
      getCustomerId() !== "onlinepayment" &&
      getCustomerId() !== "" &&
      getCustomerId() !== null &&
      getCustomerId() !== undefined
    ) {
      if (Like) {
        setLike(false);
        setLikeCount(LikeCount - 1);
        const deletLike = `customerId=${getCustomerId()}&productId=${
          props.common && props.categoryItem.productId
            ? props.categoryItem.productId
            : props.categoryItem.id
        }`;
        deleteCustomerLike(deletLike);
      } else {
        setLike(true);
        setLikeCount(LikeCount + 1);
        const createLike = `customerId=${getCustomerId()}&productId=${
          props.common && props.categoryItem.productId
            ? props.categoryItem.productId
            : props.categoryItem.id
        }&remark=${"liked"}`;
        createCustomerLike(createLike);
      }
    } else {
      dispatch({ type: "MODAL", payload: true });
    }
  };

  return (
    <>
      <div tw="flex leading-none absolute inline-flex top-0 right-0 m-2  z-20">
        <CardRating
          className={`${Like ? "hand" : ""} bi bi-hand-thumbs-up mx-3 tooltip1`}
          onClick={likeCountFunc}
        >
          <span className="tooltiptext">
            {LikeCount !== null && LikeCount !== "-" && LikeCount !== ""
              ? LikeCount
              : 0}
          </span>
        </CardRating>
        <CardRating
          className={`${Wish ? "like" : ""} bi bi-heart tooltip1`}
          onClick={wishCountFunc}
        >
          <span className="tooltiptext">
            {WishCount !== null && WishCount !== "-" && WishCount !== ""
              ? WishCount
              : 0}
          </span>
        </CardRating>
      </div>
    </>
  );
};
