import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import Noimg from "../images/no_img.jpg";
import { mostLiked } from "../api/MostLikedApi.js";
import { Link, useHistory } from "react-router-dom";
import ProductCommon from "../components/ProductLike&Wish";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import no_data from "images/no_data.png";
import SkeletonLoader from "components/SkeletonLoader";

const Container = tw.div`relative font-display overflow-hidden`;
const TabContent = tw(motion.div)`block `;
const CardContainer = tw.div`mt-4 w-full relative`;
const Card = tw(
  motion.div
)`bg-white block mx-auto  sm:mx-0 no-underline hover:shadow-2xl rounded-lg duration-500 cursor-pointer `;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative h-80 border border-gray-400 border-solid rounded-t-lg `}
`;
const CardText = tw.div`p-4 pb-1 text-gray-900 bg-gray-200 border border-gray-400 border-solid rounded-b-lg`;
const CardTitle = tw.h5`text-sm group-hover:text-yellow-600 no-underline  text-gray-600 lowercase`;
const CardContent = tw.p`mt-1 mb-2 text-sm font-medium text-custom-100  uppercase`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-custom-100`}
`;

export default ({ configImages }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    getMostLiked();
    getBanner();
    //eslint-disable-next-line
  }, []);

  const history = useHistory();

  const getMostLiked = () => {
    setLoading(true);
    mostLiked({}).then((data) => {
      const saleProduct = [];
      data.response.forEach((data) => {
        if (data.statusName.toLowerCase() === "sale") {
          saleProduct.push(data);
        }
      });
      setCategoryList(saleProduct);
      setLoading(false);
    });
  };

  const handleChange = (e) => {
    let mostLiked = "MOST LIKED PRODUCT";
    if (e.target.value !== mostLiked) history.push("NewArrivals");
  };

  const getBanner = () => {
    configImages && configImages.E_COMMERCE_BANNERS.forEach((data) => {
      if (data.key === "mostLiked") {
        setBanner(data.value);
      }
    });
  };

  return (
    <Container>
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Most Liked Product" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide ">
          Most Liked Product
        </span>
      </div>
      <div className="py-sm-5 pb-2 pb-2 mb-5 position-relative">
        <div className="container container-influid-md">
          <div className="row justify-content-between align-items-center pb-4">
            <div className="col-md-2">
              <div id="holder" tw="sm:pb-0 pb-5">
                <div className="button">
                  <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">
                    <i className="bi bi-cart4 mx-2 fw-bold"></i> Shop By
                    <i className="bi bi-arrow-bar-right fw-bold mx-2"></i>
                  </p>
                  <div className="btnTwo">
                    <p className="btnText2">
                      <i className="bi bi-cart3"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-12">
                <select
                  className="selectpicker form-select"
                  tw="px-4 py-2 focus:outline-none focus:border-custom-100 focus:ring-1 text-base"
                  name="gender"
                  id="style-11"
                  onClick={handleChange}
                >
                  <option className="text-uppercase" value="NEW ARRIVALS">
                    NEW ARRIVALS
                  </option>
                  <option
                    className="text-uppercase"
                    selected
                    value="MOST LIKED PRODUCT"
                  >
                    MOST LIKED PRODUCT
                  </option>
                </select>
              </div>
            </div>
          </div>

          {loading && <SkeletonLoader />}
          <div className="row">
            {categoryList.length === 0 && !loading ? (
              <img src={no_data} alt="no-data" className="col-md-4 m-auto" />
            ) : (
              categoryList.map((categoryItem, index) => (
                <TabContent
                  className="col-md-6 col-lg-4 col-xl-3 d-block"
                  key={index}
                >
                  <CardContainer>
                    <ProductCommon
                      categoryItem={categoryItem}
                      common="common"
                    />
                    <Card
                      className="group"
                      initial="rest"
                      whileHover="hover"
                      animate="rest"
                      onClick={() =>
                        history.push({
                          pathname: "/ProductDetails",
                          state: { detail: categoryItem },
                        })
                      }
                    >
                      <div className="overflow-hidden">
                        <CardImageContainer
                          imageSrc={
                            categoryItem.productImageUrls[0] == null
                              ? Noimg
                              : categoryItem.productImageUrls[0].imageUrl
                          }
                          tw="transform transition duration-500  group-hover:(scale-125 rotate-12)"
                        >
                          <div
                            className={`${
                              categoryItem.statusName === "Sale"
                                ? ""
                                : "bg-danger"
                            } badge`}
                            tw="absolute  top-0 left-0 m-3 bg-yellow-600 p-1"
                          >
                            {categoryItem.statusName === "Sale"
                              ? "Available"
                              : "Sold"}
                          </div>
                        </CardImageContainer>
                      </div>
                      <CardText tw="py-1 text-center">
                        <CardTitle className="capitalize">
                          {categoryItem.productTypeName}
                        </CardTitle>
                        <CardContent
                          className="text-uppercase"
                          tw="truncate w-full"
                        >
                          {categoryItem.productName}
                        </CardContent>
                      </CardText>
                    </Card>
                  </CardContainer>
                </TabContent>
              ))
            )}
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </div>
        </div>
      </div>
    </Container>
  );
};
