import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import tw from "twin.macro";
import { getCarousel } from "api/CarouselApi";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Container = tw.div`relative`;
export default () => {
  const [bannerList, setbannerList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getbannerList();
  }, []);

  const getbannerList = () => {
    setLoading(true);
    getCarousel({ offerType: "BANNERS", visibilityMode: "WEB" }).then(
      (data) => {
        setbannerList(data.response.content);
        setLoading(false);
      }
    );
  };

  return (
    <Container>
      {loading && (
        <>
          <Skeleton style={{ height: "400px", width: "100%" }}/>
          <div className="w-100 text-center d-flex justify-content-center">
            <Skeleton tw="h-20 w-20 text-center mx-3" />
            <Skeleton tw="h-20 w-20 text-center" />
          </div>
        </>
      )}
      <Carousel
        autoPlay
        infiniteLoop={true}
        emulateTouch={true}
        swipeable ={true}
        interval={5000}
        transitionTime={1000}      
        tw="text-center"
      >
        {bannerList.map((img , i) => (
          <div key={i}>
            <img
              alt="slider-images"
              src={img.offerImageString}
            />
          </div>
        ))}
      </Carousel>
    </Container>
  );
};
