import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import "styles/checkout.css";
import {
  getAddToCart,
  getCustomerId,
  setOrderKeyId,
} from "utility/HeaderConfig";
import { currentRegister, currentDateToUtcDate } from "../utility/Constant";
import { useLocation } from "react-router-dom";
import ShippingAddress from "components/ShippingAddress";
import OrderDetails from "components/OrderDetails";
import CheckoutProgressBar from "components/CheckoutProgressBar";
import ChoosePaymentMethod from "components/ChoosePaymentMethod";
import { paygOrderStatus } from "api/OnlinePaymentApi";
import { WEB_URL } from "utility/UrlConstants";
const StyledDiv = tw.div`font-display overflow-hidden`;
const BgColor = tw.div`bg-zinc-100 py-4 sm:py-10`;
export const NavLink = tw.a`no-underline`;

export default () => {
  const [address, setAddress] = useState(true);
  const [payment, setPayment] = useState(false);
  const [viewCart, setViewCart] = useState([]);
  const [total, setTotal] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Details, setDetails] = useState({
    name: "",
    mobile: "",
    email: "",
    address1: "",
    address2: "",
    pincode: "",
    landmark: "",
    districtName: "",
    stateName: "",
    countryName: "",
    customerId: "",
    customerCurrentCredit: 0,
    customerCurrentDebit: 0,
  });

  const location = useLocation();
  const myparam = location.state;

  // const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getCartList();
    //eslint-disable-next-line
  }, []);

  const getCartList = () => {
    const viewCartList = () => {
      let dataList = [];
      let basePrice = 0;
      let total = 0;
      let cgst = 0;
      let sgst = 0;

      if (myparam) {
        dataList.push(myparam);
        if (dataList[0].basePrice) {
          basePrice += Number(dataList[0].BasePrice);
        }
        if (dataList[0].total) {
          total += Number(dataList[0].total);
        }
        if (dataList[0].saleTaxList[1].amount) {
          cgst += Number(dataList[0].saleTaxList[1].amount);
        }
        if (dataList[0].saleTaxList[0].amount) {
          sgst += Number(dataList[0].saleTaxList[0].amount);
        }
        setViewCart(dataList);
        setTotal([total, cgst, sgst, basePrice]);
      } else {
        getAddToCart() &&
          JSON.parse(getAddToCart()).forEach((element, index) => {
            dataList.push(element);
            if (dataList[index].basePrice) {
              basePrice += Number(dataList[index].BasePrice);
            }
            if (dataList[index].total) {
              total += Number(dataList[index].total);
            }
            if (dataList[index].saleTaxList[1].amount) {
              cgst += Number(dataList[index].saleTaxList[1].amount);
            }
            if (dataList[index].saleTaxList[0].amount) {
              sgst += Number(dataList[index].saleTaxList[0].amount);
            }
          });
      }
      setViewCart(dataList);
      setTotal([total, cgst, sgst, basePrice]);
    };
    viewCartList();
  };

  let CartItem = [];
  viewCart.forEach((data) => {
    CartItem.push({
      productId: data.id,
      productCode: data.productCode,
      basePrice: data.basePrice,
      tax: data.saleTaxList,
      total: data.total,
      todayRate: data.todayRate,
    });
  });

  const OrderStatus = () => {
    setLoading(true);
    const productCode = viewCart.reduce((result, item) => {
      return `${result}${result ? "," : ""}${item.productCode}`;
    }, "");

    const userDefinedData = {
      UserDefined1: productCode,
      UserDefined2: total[0],
      UserDefined3: Details.branchName,
      UserDefined4: Details.branchId,
      UserDefined5: JSON.stringify(CartItem),
      UserDefined6: "",
      UserDefined7: "",
      UserDefined8: "",
      UserDefined9: "",
      UserDefined10: "",
      UserDefined11: "",
    };

    const paygRequestData = {
      acceptTerms: "Yes",
      acceptTermsDatetime: currentDateToUtcDate(currentRegister()),
      amount: 1, // total[0],
      approvalCode: "",
      authCode: "",
      authKey: "",
      authToken: "",
      authorizedAmount: 1, // total[0],
      branchId: Details.branchId,
      cancelRedirectUrl: "",
      cardDetails: "[]",
      clientSource: "",
      currencyCode: "INR",
      customerEmail: Details.email,
      customerId: Details.customerId,
      customerIdPg: Details.customerId,
      customerName: Details.name,
      customerNotes: "Sale Details",
      customerPhone: Details.mobile,
      dataSource: "ECOMM_WEB_SALES",
      hash: "",
      metalRate: 0,
      mid: "",
      orderId: "",
      payId: "",
      paymentProcessUrl: "",
      paymentPurpose: "ECOMM_WEB_SALES",
      productDesc: productCode,
      paymentType: "ALL",
      rawData: "{}",
      reasonCode: "",
      refTransactionId: "",
      responseCode: "",
      responseDatetime: currentDateToUtcDate(currentRegister()),
      responseMessage: "",
      responseUrl: "",
      retryStatus: "",
      returnUrl: `${WEB_URL}/orderStatusPage/`, 
      // returnUrl: "localhost:3000/OrderStatusPage",
      salt: "",
      status: "Initiating",
      transactionData: {},
      transactionReferenceNo: "",
      txnId: "",
      txnType: "",
      uniqueRequestId: "",
      usageFrom: "WEB",
      gatewayName: "PAYG",
      getwayName: "PAYG",
      userDefinedData,
      userName: Details.name,
      branchName: Details.branchName,
    };

    paygOrderStatus(JSON.stringify(paygRequestData)).then((data) => {
      Promise.all([
        setOrderKeyId(
          JSON.stringify({
            orderId: data.response.orderId,
            statusId: data.response.id,
          })
        ),
      ]).then((item) => {
        setLoading(false);
        window.location.href = data.response.paymentProcessUrl;
      });
    });
  };

  const changeComponent = (data) => {
    setDetails({ ...Details, ...data, customerId: getCustomerId() });
    setAddress(false);
    setPayment(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <StyledDiv>
      <BgColor>
        <div className="container">
          <CheckoutProgressBar address={address} payment={payment} />
          <div className="row my-sm-5 my-3">
            <div className="col-md-8">
              <div className="bg-gray-300">
                <div className="py-12">
                  <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg md:max-w-xl mx-2">
                    <div
                      className="px-5"
                      tw="py-2 border-b-4 border-custom-100"
                    >
                      <b>
                        {address && "SHIPPING ADDRESS"}
                        {payment && "PAYMENT DETAILS"}
                      </b>
                    </div>
                    <div className="px-sm-5 py-2 px-2 py-sm-4">
                      {address && <ShippingAddress onClick={changeComponent} />}
                      {payment && (
                        <ChoosePaymentMethod
                          Loading={Loading}
                          OrderStatus={OrderStatus}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <OrderDetails viewCart={viewCart} total={total} />
          </div>
        </div>
      </BgColor>
    </StyledDiv>
  );
};
