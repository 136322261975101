import moment from "moment";
const DATE_WISE_FORMAT = "DD-MM-YYYY";
const YEAR_WISE_FORMAT = 'YYYY-MM-DD';
const DIAMOND_PRODUCT_TYPE = 'diamond';
const COIN_NAME = 'coin';
const COINS_NAME = 'coins';
const SALES_ACCOUNTS = 'sales accounts';




//Date database to view Pacth(get) Date wise format
const dateMonthYearFormatUtcToLocal = (date: string) => {
  let localDate = "";
  if (date) {
    localDate = moment.utc(date).local().format(DATE_WISE_FORMAT);
  }
  return localDate;
};

// Date go to database (filter) check from date and to date Purity check it//
const currentDate = () => {
  let fromcurrentDate = `${moment().format("YYYY-MM-DD")} 00:00:00`;
  let tocurrentDate = `${moment().format("YYYY-MM-DD")} 23:59:59`;
  let date = {
    fromDate: moment(fromcurrentDate, `YYYY-MM-DD HH:mm:ss`).utc().format(),
    toDate: moment(tocurrentDate, `YYYY-MM-DD HH:mm:ss`).utc().format(),
  };
  return date;
};


const filterFormatToDate = (date: string) => {
  let localDate = '';
  const dateAndTime = `${date} 23:59:59`;
  if (date) {
    localDate = moment(dateAndTime, 'YYYY-MM-DD HH:mm:ss')
      .utc()
      .format();
  }
  return localDate;
};

//// Current date and Time ///
const currentRegister = () => {
  const enrollmentDate = `${moment().format("YYYY-MM-DD")}`;
  const enrollmentTime = `${moment().format("HH:mm:ss")}`;
  const enrollmentDateWithTime = `${enrollmentDate}T${enrollmentTime}Z`;
  return enrollmentDateWithTime;
};

// single curentdate pass some moment formate date Book an appointment Page used //
const currentDateToUtcDate = (date: string) => {
  let utcDate = '';
  if (date) {
    utcDate = moment(date, `${YEAR_WISE_FORMAT} HH:mm:ss`)
      .utc()
      .format();
  }
  return utcDate;
};


export { dateMonthYearFormatUtcToLocal, currentDate, currentRegister, currentDateToUtcDate , filterFormatToDate, DIAMOND_PRODUCT_TYPE, COINS_NAME, COIN_NAME, SALES_ACCOUNTS  };
