import React from "react";
import Noimg from "../images/no_img.jpg";
import "twin.macro";

export default function OrderDetails({ viewCart, total }) {
  return (
    <div className="col-md-4  my-sm-0 my-4">
      <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg md:max-w-xl mx-2">
        <div className="px-5" tw="py-2 border-b-4 border-custom-100">
          <b> Order Details</b>
        </div>
        <table className="table table-borderless m-0">
          <tbody>
            {viewCart.map((list, index) => (
              <tr className="border-bottom" key={index}>
                <td>
                  <td>
                    <img
                      src={
                        list.productImageUrlList && list.productImageUrlList[0]
                          ? list.productImageUrlList[0]
                          : Noimg
                      }
                      alt="Zinger Jewellers-product"
                      tw="w-20"
                    />
                  </td>
                  <td>
                    <div className="ms-4">
                      <span tw="text-sm m-0 block">{list.productName}</span>
                      <p tw="m-0 text-sm">
                        <i className="bi bi-tag-fill"></i> {list.productCode}
                      </p>
                      <small tw="text-xs text-custom-100">
                        {list.productTypeName}
                      </small>
                    </div>
                  </td>
                </td>
                <td>
                  <span className="product-price">
                    ₹ {list.BasePrice.toFixed(2)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="price-detail-section orderItem-box " tw="border border-custom-100 border-dashed">
          <p className="text-success mb-2 fw-bold">
            Order Item ( {viewCart.length} Items )
          </p>
          <div className="row">
            <div className="label-col col-sm-7 col">
              <p>Sub Total</p>
            </div>
            <div className="price-col col-sm-5 col">
              <p>
                <span>₹ </span>
                <span>{total[3] && total[3].toFixed(2)}</span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="label-col col-sm-7 col">
              <p>CGST</p>
            </div>
            <div className="price-col col-sm-5 col">
              <p className="">
                <span>₹ </span>
                <span>{total[1] && total[1].toFixed(2)}</span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="label-col col-sm-7 col">
              <p>SGST</p>
            </div>
            <div className="price-col col-sm-5 col">
              <p className="">
                <span>₹ </span>
                <span>{total[2] && total[2].toFixed(2)}</span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="label-col col-sm-7 col ">
              <span className="you-pay fw-bold text-uppercase">Total</span>
              <small className="inc-tax" tw="text-xs">
                {" "}
                (Incl of all taxes.)
              </small>
            </div>
            <div className="price-col col-sm-5 col">
              <span className="you-pay-amt fw-bold text-uppercase">
                <span>₹ </span>
                <span>{total[0] && total[0].toFixed(2)}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
