import React, { memo, useEffect, useState } from "react";
import "twin.macro";
import { AnimatePresence, motion } from "framer-motion";
import { useCartContext } from "context/UseCartContext.js";
import Divider from "../images/MyScheme/divider.png";
import { getUserAccount, pwdReset } from "api/CustomerApi.js";
import { generateotp, getOtp, validateOtp } from "api/OtpApi.js";
import { getAllRole } from "api/RoleApi";
import toast from "react-hot-toast";

function ForgetPassword(props) {
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [MobileInvalid, setMobileInvalid] = useState("");
  const [enterOtp, setEnterOtp] = useState({
    id: "",
    username: "",
    accountId: "",
    otpCode: "",
    rolesList: "",
  });
  const [checkOtp, setCheckOtp] = useState(false);
  const [emptyField, setEmptyField] = useState(false);

  const [invalidOTP, setInvalidOTP] = useState(false);
  const [Loading, setLoading] = useState(false);

  const { dispatch } = useCartContext();

  const verifyMobile = (e) => {
    e.preventDefault();
    postAjaxRequest();
  };

  useEffect(() => {
    const getRole = () => {
      const filterData = {
        name: "Customer",
      };
      getAllRole(filterData).then((data) => {
        setEnterOtp({ ...enterOtp, rolesList: [data.response[0].id] });
      });
    };

    getRole();
    //eslint-disable-next-line
  }, []);

  const postAjaxRequest = () => {
    const mobileReg = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    // Invalid message validate //
    if (!mobileReg.test(mobileNo) && mobileNo !== "") {
      setMobileInvalid("Mobile Number Field is Invalid");
    }

    // Empty Validate Field //
    const validateField = () => {
      if (mobileNo && mobileReg.test(mobileNo)) {
        setMobileInvalid("");
        return true;
      } else {
        setEmptyField(true);
        return false;
      }
    };

    if (validateField()) {
      const loginDetails = {
        username: mobileNo,
      };
      const responseData = getUserAccount(JSON.stringify(loginDetails));
      responseData.then((res) => {
        if (res.response.content && res.response.content.length > 0) {
          const responseOtp = generateotp(JSON.stringify(loginDetails));
          responseOtp.then((resOtp) => {
            if (resOtp.response !== null) {
              const responseGetOtp = getOtp(resOtp.response);
              responseGetOtp.then((data) => {
                let resetPassword = {
                  id: data.response.id,
                  username: mobileNo,
                  accountId: res.response.content[0].id,
                  otpCode: data.response.code,
                };
                setEnterOtp({ ...enterOtp, ...resetPassword });
              });
            }
          });
        } else {
          setMobileInvalid(
            "User Details Please check Mobile Number & User not available in this Mobile Number"
          );
        }
      });
    }
  };

  const validOTP = (e) => {
    e.preventDefault();
    let otpValid = /^(\+\d{1,3}[- ]?)?\d{6}$/;
    if (otpValid.test(enterOtp.otpCode) && enterOtp.otpCode !== "") {
      validateOtp(enterOtp.id, enterOtp.otpCode).then((data) => {
        if (data.response === true) {
          setCheckOtp(true);
          setInvalidOTP(false);
        }
      });
    } else {
      setInvalidOTP(true);
    }
  };

  const resetPwd = () => {
    if (password !== "") {
      setLoading(true);
      let resetPassword = {
        id: enterOtp.accountId,
        username: enterOtp.username,
        password: password,
        rolesList: enterOtp.rolesList,
      };
      // console.log(resetPassword);
      pwdReset(resetPassword).then((data) => {
        toast.success("Password Reset successfully...!", {
          duration: 1000,
          position: "top-right",
        });
        setTimeout(() => {
          dispatch({ type: "MODAL", payload: false });
        }, 1000);
        setLoading(false);
      });
    } else {
      setEmptyField(true);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -100, opacity: 0 }}
        className="col-12 col-sm-7  overflow-hidden"
      >
        <div tw="p-2 py-5  sm:p-5 xl:p-16">
          <div tw="uppercase tracking-wide  text-xl sm:text-3xl text-black font-semibold text-center">
            Forget Password
            <img src={Divider} alt="heading-divider" tw="m-auto mt-2 w-1/4" />
          </div>
          <form>
            <div tw="my-6">
              <div tw="relative">
                <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className="bi bi-person"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  </svg>
                </div>
                {enterOtp.id && (
                  <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <i className="bi bi-check-circle text-success"></i>
                  </div>
                )}
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  autocomplete="off"
                  id="email-address-icon"
                  value={mobileNo}
                  onChange={(e) => {
                    setMobileNo(e.target.value);
                  }}
                  tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                  placeholder="Enter Your Registered Mobile No"
                  className={`${
                    (emptyField && mobileNo === "") || MobileInvalid !== ""
                  }  ${
                    enterOtp.id && "bg-secondary bg-opacity-25 border-success "
                  }`}
                  disabled={enterOtp.id && true}
                />
              </div>
              {emptyField && mobileNo === "" ? (
                <p tw="text-red-500 mt-1 mx-5 text-sm">
                  Enter Your Mobile Number
                </p>
              ) : (
                " "
              )}

              {MobileInvalid !== "" && mobileNo !== "" ? (
                <p tw="text-red-500 mt-1 mb-0 mx-5 text-sm">{MobileInvalid}</p>
              ) : (
                ""
              )}
            </div>

            {enterOtp.id !== "" && (
              <div tw="my-6">
                <div tw="relative">
                  <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-key"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                      <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </div>
                  {invalidOTP && (
                    <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <i className="bi bi-exclamation-circle text-danger"></i>
                    </div>
                  )}
                  {checkOtp && (
                    <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <i className="bi bi-check-circle text-success"></i>
                    </div>
                  )}
                  <input
                    type="text"
                    id="email-address-icon"
                    tw="focus:outline-none focus:border-custom-100
                      focus:ring-1   bg-gray-200 border border-gray-300 
                      text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                    placeholder="Enter Your OTP No"
                    onChange={(e) => {
                      setEnterOtp((enterOtp) => ({
                        ...enterOtp,
                        otpCode: e.target.value,
                      }));
                    }}
                    disabled={checkOtp && true}
                    className={`${invalidOTP ? "border border-danger" : ""} ${
                      checkOtp && "bg-secondary bg-opacity-25 border-success"
                    }`}
                    value={enterOtp.otpCode}
                  />
                </div>
                {invalidOTP && (
                  <p tw="text-red-500 mt-1 mx-5 text-sm">
                    Please Enter Valid OTP Code... !
                  </p>
                )}
              </div>
            )}
            {checkOtp && (
              <div tw="my-6">
                <div tw="relative">
                  <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-shield-lock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                      <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />
                    </svg>
                  </div>
                  {emptyField && password === "" && (
                    <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <i className="bi bi-exclamation-circle text-danger"></i>
                    </div>
                  )}
                  <input
                    type="password"
                    id="email-address-icon"
                    tw="focus:outline-none focus:border-custom-100
                      focus:ring-1   bg-gray-200 border border-gray-300 
                      text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                    placeholder="Enter Your New Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className={`${
                      emptyField && password === ""
                        ? "border border-danger"
                        : ""
                    }`}
                  />
                </div>
                {emptyField && password === "" ? (
                  <p tw="text-red-500 mt-1 mx-5 text-sm">
                    Enter Your New Password
                  </p>
                ) : (
                  " "
                )}
              </div>
            )}
            {checkOtp ? (
              <button
                type="button"
                tw="mt-1 transition w-full text-white  bg-custom-100 active:bg-custom-100 tracking-wider font-bold focus:outline-none   rounded-lg text-base py-3 text-center uppercase"
                onClick={resetPwd}
                disabled={Loading ? true : ""}
                className={`${Loading ? "bg-secondary" : ""} btn2`}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-white"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="mx-2 text-white">Loading . . .</span>
                  </>
                ) : (
                  <>Reset Password</>
                )}
              </button>
            ) : (
              <>
                {enterOtp.id !== "" ? (
                  <button
                    type="submit"
                    tw="mt-1 transition w-full text-white  bg-custom-100 active:bg-custom-100 tracking-wider font-bold focus:outline-none   rounded-lg text-base py-3 text-center uppercase"
                    onClick={validOTP}
                    disabled={Loading ? true : ""}
                    className={`${Loading ? "bg-secondary" : ""} btn2`}
                  >
                    {Loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-white"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="mx-2 text-white">Loading . . .</span>
                      </>
                    ) : (
                      <>Verify OTP Number</>
                    )}
                  </button>
                ) : (
                  <button
                    type="submit"
                    tw="mt-1 transition w-full text-white  hover:bg-red-800 bg-custom-100 active:bg-custom-100 tracking-wider font-bold focus:outline-none   rounded-lg text-base py-3 text-center uppercase"
                    onClick={verifyMobile}
                    disabled={Loading ? true : ""}
                    className={`${Loading ? "bg-secondary" : ""} `}
                  >
                    {Loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-white"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="mx-2 text-white">Loading . . .</span>
                      </>
                    ) : (
                      <>Verify Mobile Number</>
                    )}
                  </button>
                )}
              </>
            )}
          </form>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default memo(ForgetPassword);
