import axios from "axios";
import { BASE_URL } from '../utility/UrlConstants';
import { getHeaderConfig , getBearerToken} from '../utility/HeaderConfig';

const mostLiked = () => {
    axios.defaults.headers.common.token = `Bearer ${ getBearerToken()}`;
    return axios
      .get(`${BASE_URL}/products-likes/most-liked-product`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

export { mostLiked }
 