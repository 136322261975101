import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";

const generateotp = (requestBody: string) => {
  return axios
    .post(
      `${BASE_URL}/admin/others/otpdetail/metadata/generateotp`,
      requestBody,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getOtp = (id: String) => {
  return axios
    .get(
      `${BASE_URL}/admin/others/otpdetail/metadata/get?id=${id}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const validateOtp = (id, code) => {
  return axios
    .get(
      `${BASE_URL}/admin/others/otpdetail/metadata/validateotp?id=${id}&otpCode=${code}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getActiveUserAccount = (userName) => {
  return axios
    .get(
      `${BASE_URL}/savingsscheme/customersavingdetails/active-autosearch?mobileNo=${userName}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};



export { generateotp, getOtp, validateOtp, getActiveUserAccount };
