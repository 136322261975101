import male from "../images/gender/male.png";
import female from "../images/gender/female.png";
import kids from "../images/gender/kids.png";
import couple from "../images/gender/couple.png";

export const genderBasedIcons = [
  {
    title: "MALE",
    imgageSrc: male,
    gender: "M",
  },
  {
    title: "FEMALE",
    imgageSrc: female,
    gender: "F",
  },
  {
    title: "COUPLE",
    imgageSrc: couple,
    gender: "C",
  },
  {
    title: "KIDS",
    imgageSrc: kids,
    gender: "K",
  },
  {
    title: "UNISEX",
    imgageSrc: couple,
    gender: "U",
  },
];
