import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'

function BookAppointment({category}) {
 const history = useHistory();
  return (
    <div className='col-12 col-lg-5'>
      <div id="holder">
        <div className="button"  onClick={()=> history.push("/BookAnAppointment", category)}>
          <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">Book An Enquiry</p>
            <div className="btnTwo">
            <p className="btnText2"><i className="bi bi-calendar2-week mx-2 text-white" tw="text-xl "></i> </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default memo(BookAppointment);
