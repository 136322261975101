import tw from "twin.macro";
// import SliderImage from "react-zoom-slider";
import { ProductDetails, likeCount, wishCount } from "../api/ProducDetailsApi";
import { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import Noimg from "../images/no_img.jpg";
import { getProduct } from "api/ProductApi";
import { filterFormatToDate } from "utility/Constant";
import {
  getAddToCart,
  getBranchId,
  getCustomerId,
  setAddToCart,
} from "../utility/HeaderConfig.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import toast, { Toaster } from "react-hot-toast";
// import Pincode from "components/Pincode";
import BookAppointmentButton from "components/BookAppointmentButton";
import ProductPriceDetails from "components/ProductPriceDetails";
import { useCartContext } from "context/UseCartContext";
import RelatedProduct from "components/RelatedProduct";
import Magnifier from "../components/Magnifier";
import { Carousel } from "react-responsive-carousel";
import { dayClose } from "api/NewSchemeApi";
import moment from "moment";

const StyledDiv = tw.div`font-display overflow-hidden md:overflow-visible `;

export default ({ configImages }) => {
  const [Details, setDetails] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [Img, setImg] = useState([]);
  const [Spec, setSpec] = useState([]);
  const [LikeCount, setLikeCount] = useState("");
  const [WishCount, setWishCount] = useState("");
  const [banner, setBanner] = useState([]);
  const [currentImg, setCurrentImg] = useState([]);

  // const [branchName, setBranchName ] = useState("");
  const history = useHistory();
  const location = useLocation();
  const myparam = location.state.detail
    ? location.state.detail
    : location.state;

  const { dispatch } = useCartContext();

  useEffect(() => {
    getProdDetails();
    getBanner();
    //eslint-disable-next-line
  }, [myparam]);

  const getBanner = () => {
    configImages && configImages.E_COMMERCE_BANNERS.forEach((data) => {
      if (data.key === "productDetails") {
        setBanner(data.value);
      }
    });
  };


  const dayCloseDay = (ProductDetails) => {
    dayClose({
      module: "Sales",
      branchId: getBranchId(),
    }).then((data) => {
      if(data.response.length > 0){
        let dateString = data.response[0].activeDate;
        let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
        let dayCloseDate = date.format('YYYY-MM-DD');
        getRateApi(ProductDetails,dayCloseDate);
      }   
    });
  };
  

  const getRateApi = (ProductDetails,dayCloseDate) => {
    let filter = {
      branch: getBranchId(),
      removeNTP: "NTP-",
      removeHP: "HP-",
      beforeCreatedAt: filterFormatToDate(dayCloseDate),
      productCode: ProductDetails.productCode,
    };
    getProduct(10, filter).then((data) => {
      let Rate = data.response.content[0];
      let ProductDetail = ProductDetails;
      let BasePrice = Rate.basePrice + Rate.diamondTotal + Rate.gemTotal;
      // let Certificate = JSON.parse(ProductDetail.otherCharges)[1].stone.length > 0 && JSON.parse(ProductDetail.otherCharges)[1].stone[0].certificatenumber ;
      let Certificate;
      JSON.parse(ProductDetail.otherCharges).map((value) =>{
        if(value.stone && value.stone.length > 0){
            Certificate = value.stone[0].certificatenumber ;
        }
      })
      
      setDetails({ ...Rate, ...ProductDetail, BasePrice, Certificate });
    });
  };

  const getProdDetails = () => {
    setLoader(false);
    ProductDetails(location.state.detail ? myparam.productId : myparam.id).then(
      (data) => {
        let imageData = [];
        if (
          data.response.productImageUrlList !== null &&
          data.response.productImageUrlList !== "" &&
          data.response.productImageUrlList !== "-"
        ) {
          data.response.productImageUrlList.forEach((image) => {
            imageData.push({
              image: image,
            });
            setImg(imageData);
            setCurrentImg(data.response.productImageUrlList[0]);
          });
        } else {
          imageData.push({
            image: Noimg,
          });
          setImg(imageData);
          setCurrentImg(Noimg);
        }
        setSpec(data.response.prodSpecMappingList);      
        setLoader(true);
        dayCloseDay(data.response);
      }
    );
    likeCount(location.state.detail ? myparam.productId : myparam.id).then(
      (data) => {
        setLikeCount(data.response > 0 ? data.response : 0);
      }
    );
    wishCount(location.state.detail ? myparam.productId : myparam.id).then(
      (data) => {
        setWishCount(data.response > 0 ? data.response : 0);
      }
    );
  };

  // const wish = (Wish) => {
  //   if (Wish) {
  //     setWishCount(WishCount - 1);
  //   } else {
  //     setWishCount(WishCount + 1);
  //   }
  // };

  // const like = (Like) => {
  //   if (Like) {
  //     setLikeCount(LikeCount - 1);
  //   } else {
  //     setLikeCount(LikeCount + 1);
  //   }
  // };

  const addToCart = (productCode) => {
    const productData = JSON.parse(
      getAddToCart() !== undefined ? getAddToCart() : "[]"
    );
    let products = [];
    products = productData;
    products.push(Details);
    let storeCardItem = JSON.parse(
      getAddToCart() !== undefined ? getAddToCart() : "[]"
    );
    const validateProduct = storeCardItem.some(
      (item) => item.productCode === productCode
    );

    if (!validateProduct) {
      setAddToCart(JSON.stringify(products));
      dispatch({ type: "ADD", payload: JSON.parse(getAddToCart()) });
      toast.success("Product Added To The Cart", {
        duration: 2000,
        position: "top-right",
      });
    } else {
      toast.error("Product Already Added To The Cart !", {
        duration: 3000,
        position: "top-right",
      });
    }
  };

  const buynow = (productCode) => {
    const productData = JSON.parse(
      getAddToCart() !== undefined ? getAddToCart() : "[]"
    );
    let products = [];
    products = productData;
    products.push(Details);
    let storeCardItem = JSON.parse(
      getAddToCart() !== undefined ? getAddToCart() : "[]"
    );
    const validateProduct = storeCardItem.some(
      (item) => item.productCode === productCode
    );
    if (!validateProduct) {
      if (getCustomerId()) {
        setAddToCart(JSON.stringify(products));
        dispatch({ type: "ADD", payload: JSON.parse(getAddToCart()) });
        history.push("/CheckoutPage", Details);
      } else {
        dispatch({ type: "MODAL", payload: true });
      }
    } else {
      if (getCustomerId()) {
        history.push("/CheckoutPage", Details);
      } else {
        dispatch({ type: "MODAL", payload: true });
      }
    }
  };

  const thumb = (index) => {
    console.log(index);
    setCurrentImg(Img[index].image);
  };
  
  return (
    <StyledDiv>
      <Toaster position="top-right" />
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Jewellery Product Details" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide ">
          {Details.productName?.toLowerCase()}
        </span>
      </div>
      <section className="py-sm-5 py-3 position-relative" tw=" my-2 sm:my-10">
        <div className="container-lg">
          <div className="row">
            {isLoader ? (
              <>
                <div className="col-md-6">
                  <div className="position-static" tw="z-0">
                    <Magnifier
                      imgSrc={currentImg}
                      imgWidth={`100%`}
                      imgHeight={`100%`}
                      magnifierRadius={120}
                    />
                    <Carousel
                      emulateTouch={true}
                      swipeable={true}
                      interval={5000}
                      transitionTime={1000}
                      tw="text-center"
                      onClickThumb={thumb}
                      className="role"
                    >
                      {Img.map((data) => (
                        <div>
                          <img alt="slider-images" src={data.image} />
                        </div>
                      ))}
                    </Carousel>
                    {/* <SliderImage
                      data={Img}
                      showDescription={true}
                      direction="right"
                      width="100%"
                    /> */}
                  </div>
                </div>
                <div className="col-md-6" tw="sm:py-0 py-10">
                  <h2 tw="pb-2 uppercase">{Details.productName}</h2>
                  <div className="row">
                    <table tw="leading-9" className="col-md-12 col-lg-6">
                      <tr tw="mb-3">
                        <td tw="px-3"> Product Code</td>:
                        <td tw="px-3">{Details.productCode}</td>
                      </tr>
                      <tr>
                        <td tw="px-3">Product Type</td>:
                        <td tw="px-3">
                          {Details.productTypeName}
                          <div className="badge mx-2" tw="bg-yellow-600 p-1">
                            {" "}
                            {Details.purityName}
                          </div>
                        </td>
                      </tr>
                      {Details.huId !== null &&
                      Details.huId !== "-" &&
                      Details.huId !== "" ? (
                        <tr>
                          <td tw="px-3">Huid</td>:
                          <td tw="px-3">{Details.huId}</td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </table>
                    <div className="col-md-6">
                      <div tw="mt-4 md:mt-0 lg:mt-4">
                        <i className="bi bi-heart"></i> {WishCount} Wish
                        <i className="bi bi-hand-thumbs-up ms-3"></i>{" "}
                        {LikeCount} Like
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-xl-5">
                      <div className="py-2">
                        <h3 className="price " >
                          <span tw="text-base">Price :</span> ₹ {Details.total}
                        </h3>
                        <small>*Inclusive of all Details</small>
                      </div>
                      <div tw="text-green-600 py-1">
                        <h6 tw="m-0">
                          <span>
                            Available at {Details.branchName} Branch
                          </span>
                        </h6>                       
                      </div>
                     {Details.Certificate > 0 &&  <p tw="text-sm my-2">Certificate No :  {Details.Certificate}</p>}
                    </div>
                    <div className="col-md-6  col-lg-6 col-xl-7">
                      <h6 tw="text-custom-100  mt-3 mb-3">
                        Weight Details (gms)
                      </h6>
                      <table className="table table-bordered mb-2">
                        <thead>
                          <tr>
                            <th>G.WT</th>
                            <th>L.WT</th>
                            <th>N.WT</th>
                          </tr>
                        </thead>
                        <tbody className="border-top-0 ">
                          <tr>
                            <td>{Details.grossWeight}</td>
                            <td>{Details.lessWeight}</td>
                            <td>{Details.netWeight}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p tw="text-sm  leading-7" className="line-clamp ">
                    {Details.description !== null &&
                    Details.description !== "-" &&
                    Details.description !== ""
                      ? Details.description
                      : "No Description"}
                  </p>
                  <div className="row">
                    {Spec.length > 0 && (
                      <div className="col-md-4">
                        <h6 tw="text-custom-100  mb-5">
                          Specification Details
                        </h6>
                       
                          <table
                            tw="leading-9"
                            className="col-md-6 w-100"
                           
                          >
                             {Spec.map((data, index) => (
                            <tr tw="mb-3"  key={index}>
                              <td tw="px-3">{data.specificationName.split('_')[0]} </td> :
                              <td tw="px-3">
                                {data.specificationValue !== "NA"
                                  ? data.specificationValue
                                  : "-"}
                              </td>
                            </tr> 
                             ))}                          
                          </table>
                       
                      </div>
                    )}
                    <div
                      className={`${
                        Spec.length > 0 ? "col-md-8" : "col-md-12"
                      }`}
                    >
                      <ProductPriceDetails Details={Details} />
                    </div>
                  </div>
                  <div className="row py-4 justify-content-center">
                    <BookAppointmentButton category={Details} />
                    {/* <Pincode /> */}
                    {/* <div className=" col-12 col-lg-4 my-lg-0 my-3">
                      <div id="holder">
                        <div
                          className="button"
                          onClick={() => addToCart(Details.productCode)}
                        >
                          <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">
                            Add To Cart
                          </p>
                          <div className="btnTwo">
                            <p className="btnText2">
                              <i className="bi bi-cart-plus"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className=" col-12 col-lg-4">
                      <div id="holder">
                        <div
                          className="button"
                          onClick={() => buynow(Details.productCode)}
                        >
                          <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">
                            Buy now
                          </p>
                          <div className="btnTwo">
                            <p className="btnText2">
                              <i className="bi bi-cash"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6">
                  <Skeleton tw="w-full h-full" />
                </div>
                <div className="col-md-6">
                  <Skeleton tw="pb-2 uppercase w-3/4 sm:h-1/12 h-0 mt-2 mb-0" />
                  <div className="row mx-2">
                    <table tw="leading-9" className="col-md-6">
                      <tr tw="mb-3">
                        <Skeleton tw="w-full" />
                      </tr>
                      <tr>
                        <Skeleton tw="w-full" />
                      </tr>
                      <tr>
                        <Skeleton tw="w-full" />
                      </tr>
                    </table>
                    <div className="col-md-6">
                      <div tw="mt-1">
                        <Skeleton tw="w-5/12" />
                        <Skeleton tw="w-5/12 my-4" />
                        <Skeleton tw="w-5/12" />
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <div tw="mt-1">
                      <Skeleton tw="uppercase w-3/4 " />
                    </div>
                  </div>
                  <div tw="text-green-600 py-1">
                    <h6>
                      <Skeleton tw="w-5/12" />
                    </h6>
                  </div>
                  <p tw="text-sm">
                    <Skeleton tw="w-6/12" />
                  </p>
                  <Skeleton tw="w-4/12 my-3" />
                  <table className="table table-bordered">
                    <Skeleton count={3} tw="w-3/4" />
                  </table>
                  <div className="row">
                    <Skeleton count={3} tw="w-2/4" />
                  </div>
                  <Skeleton tw="w-1/4 h-1/12 mb-20" />
                </div>
              </>
            )}
          </div>
          <a
            href={`https://api.whatsapp.com/send?phone="+91 09629157099"&text=I am intrested to buy this product * Tag No : ${Details.productCode} , Branch - ${Details.branchName}`}
            className="scroll-to-top position-fixed  bottom-0 end-0 "
            tw="mx-6 my-20"
            target="_blank"
            rel="noreferrer" 
          >
            <div
              className="rounded-circle text-white p-1"
              style={{ cursor: "pointer", backgroundColor: "#25d366" }}
            >
              <i
                className="bi bi-whatsapp px-2 fs-2"
                style={{ fontSize: "11px" }}
              ></i>
            </div>
          </a>
        </div>
      </section>
      <RelatedProduct Details={Details} />
    </StyledDiv>
  );
};
