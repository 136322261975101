import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";


const schemeStatus = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/savingsscheme/customersavingdetails/get-page?page=0&size=50&sort=createdAt,desc&chitEnrolDtlsFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const paymentHitory = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/savingsscheme/chitcustomerpayment/get-page?page=0&size=50&sort=createdAt,desc&chitCustPaymntDtlsFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const onlinePayment = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/accounts/onlinePayment/get-page?page=0&size=50&sort=createdAt,desc&onlinePaymentFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export { schemeStatus , paymentHitory, onlinePayment };
