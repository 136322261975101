import axios from "axios";
import { BASE_URL } from '../utility/UrlConstants';
import { getHeaderConfig } from '../utility/HeaderConfig';

const myWishList = (filters: String) => {
    return axios
      .get(`${BASE_URL}/customer-wishlist/get-by-customer?customerId=${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

    export { myWishList }