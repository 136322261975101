import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import { paymentHitory, schemeStatus } from "api/MySchemeApi.js";
import { getBranchId, getCustomerId } from "../utility/HeaderConfig";
import {
  currentDate,
  currentDateToUtcDate,
  currentRegister,
  dateMonthYearFormatUtcToLocal,
} from "../utility/Constant";
import Divider from "../images/MyScheme/divider.png";
import { dataList } from "api/SkeletonData";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import chitImg from "../images/MyScheme/chitImg.webp";
import PaymentHistory from "./PaymentHistory";
import no_data from "images/no_data.png";
import { paygOrderStatus } from "api/OnlinePaymentApi";
import { PurityApi } from "api/PurityApi";
import { WEB_URL } from "utility/UrlConstants";

const Thead = tw.th`text-xs uppercase tracking-wider`;
const Tbody = tw.td`text-sm flex`;
const TabContent = tw(motion.div)`block`;

export default ({ chitStatus, Details }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [SchemeData, setSchemeData] = useState([]);
  const [PayHistory, setPayHistory] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState({});
  const [loading, setLoading] = useState(true);
  const [payLoading, setPayLoading] = useState(false);
  const [currentRate, setCurrentRate] = useState("");

  useEffect(() => {
    getschemeStatus();
    //eslint-disable-next-line
  }, [chitStatus]);

  useEffect(() => {
    getProductRate();
  }, []);

  const getProductRate = () => {
    const filterData = {
      createdAtFrom: currentDate().fromDate,
      createdAtTo: currentDate().toDate,
      branch: getBranchId(),
    };
    PurityApi(filterData).then((data) => {
      // setproductRate(data.response);
      data.response.forEach((item) => {
        if (item.purity === "22K - 91.6%" || item.purity === "90%") {
          setCurrentRate(item.rate);
        }
      });
    });
  };

  const getschemeStatus = () => {
    setLoading(true);
    let id = getCustomerId();
    const response = schemeStatus({
      status: chitStatus,
      branchDetailsEntity: getBranchId(),
      customerEntity: id,
    });
    response.then((data) => {
      setSchemeData(data.response.content);
      setLoading(false);
    });
  };

  const payment = (data) => {
    setSelectedScheme(data);
    const response = paymentHitory({
      chitEnrollmentDetailsEntity: data.id,
      branchDetailsEntity: getBranchId(),
    });
    response.then((data) => {
      setPayHistory(data.response);
      setShowModal(true);
    });
  };

  const close = () => {
    setShowModal(false);
  };

  // Chit online payment //

  const OrderStatus = (data) => {
    setPayLoading(true);
    console.log(Details);
    const userDefinedData = {
      UserDefined1: data.chitId,
      UserDefined2: data.chitAmount,
      UserDefined3: Details.branchName,
      UserDefined4: Details.branchId,
      UserDefined5: parseInt(data.chitPaymentListSize) + 1,
      UserDefined6: data.chitAmount,
      UserDefined7: Number(data.chitAmount / currentRate).toFixed(3),
      UserDefined8: currentRate,
      UserDefined9: 0,
      UserDefined10: data.chitAmount,
      UserDefined11: parseFloat(data.chitAmount) + parseFloat(0),
    };

    const paygRequestData = {
      acceptTerms: "Yes",
      acceptTermsDatetime: currentDateToUtcDate(currentRegister()),
      amount: 1, // data.chitAmount,
      approvalCode: "",
      authCode: "",
      authKey: "",
      authToken: "",
      authorizedAmount: 1, // data.chitAmount,
      branchId: Details.branchId,
      cancelRedirectUrl: "",
      cardDetails: "[]",
      clientSource: "",
      currencyCode: "INR",
      customerEmail: Details.email,
      customerId: Details.customerId,
      customerIdPg: Details.customerId,
      customerName: Details.name,
      customerNotes: "Chit Details",
      customerPhone: Details.mobile,
      dataSource: "CHIT_APP",
      hash: "",
      metalRate: 0,
      mid: "",
      orderId: "",
      payId: "",
      paymentProcessUrl: "",
      paymentPurpose: "ECOMM_WEB_SALES",
      productDesc: data.chitId,
      paymentType: "ALL",
      rawData: "{}",
      reasonCode: "",
      refTransactionId: "",
      responseCode: "",
      responseDatetime: currentDateToUtcDate(currentRegister()),
      responseMessage: "",
      responseUrl: "",
      retryStatus: "",
      returnUrl: `${WEB_URL}/orderStatusPage/`,     
      salt: "",
      status: "Initiating",
      transactionData: {},
      transactionReferenceNo: "",
      txnId: "",
      txnType: "",
      uniqueRequestId: "",
      usageFrom: "WEB",
      gatewayName: "PAYG",
      getwayName: "PAYG",
      userDefinedData,
      userName: Details.name,
      branchName: Details.branchName,
    };

    console.log(paygRequestData);
    paygOrderStatus(JSON.stringify(paygRequestData)).then((data) => {
      setPayLoading(false);
      window.location.href = data.response.paymentProcessUrl;
    });
  };

  const controllBtn = (status) => {
    if (
      status.toLowerCase() === "waiting_for_approval" ||
      status.toLowerCase() === "cancelled"
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="container-fluid p-0 px-sm-4 container-xl">
      <div className="row m-0 position-relative">
        {loading && (
          <div className="row ">
            {dataList.slice(0, 8).map((data , index) => (
              <div className="col-md-6 py-2" key={index}>
                <Skeleton tw="w-full" style={{ height: "200px" }} />
                <div className="card my-2 text-center py-2">
                  <Skeleton className="px-3" tw="w-11/12" />
                  <Skeleton className="px-3" tw="w-11/12" />
                  <Skeleton className="px-3" tw="w-11/12" />
                  <Skeleton className="px-3" tw="w-11/12" />
                </div>
              </div>
            ))}
          </div>
        )}
        {SchemeData.length === 0 && !loading ? (
          <img src={no_data} alt="no-data" className="col-md-5 m-auto" />
        ) : (
          SchemeData.map((data) => (
            <TabContent
              className="col-12 col-md-12 col-lg-6 py-3"
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "block",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                },
              }}
              transition={{ duration: 0.4 }}
              initial={data.status === chitStatus ? "current" : "hidden"}
              animate={data.status === chitStatus ? "current" : "hidden"}
            >
              <div tw="bg-white rounded-xl shadow-md overflow-hidden md:flex lg:block">
                <div tw="md:w-2/6 lg:w-full">
                  <img
                    tw="h-48 w-full object-cover  md:h-full lg:h-48"
                    src={chitImg}
                    alt="chit_img"
                  />
                </div>
                <div tw=" p-4 sm:(p-5 pt-2)  md:w-full">
                  <div tw="uppercase mb-3 tracking-wide text-2xl text-custom-100 font-semibold text-center">
                    {data.metaChitName}
                    <img
                      src={Divider}
                      alt="heading-divider"
                      tw="m-auto mt-1 w-1/6"
                    />
                  </div>
                  <p
                    tw="m-0 text-sm p-2 overflow-y-scroll"
                    className="border custom_scroll "
                    style={{ height: "78px" }}
                  >
                    {data.metaChitDesc ? (
                      data.metaChitDesc
                    ) : (
                      <p tw="m-0 flex items-center justify-center h-full">
                        No Description
                      </p>
                    )}
                  </p>
                  <table className="table table-responsive table-borderless my-3 table-sm ">
                    <tbody>
                      <tr>
                        <Thead scope="col">Chit Id</Thead>
                        <Tbody scope="col">
                          :{" "}
                          <span
                            tw="mx-2 w-full"
                            className="d-inline-block "
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={data.chitId}
                          >
                            {data.chitId}
                          </span>
                        </Tbody>
                        <Thead scope="col">Due Amt</Thead>
                        <Tbody scope="col">
                          : <span tw="mx-2">{data.chitAmount}</span>
                        </Tbody>
                      </tr>
                      <tr>
                        <Thead scope="col">Enroll Dt</Thead>
                        <Tbody scope="col">
                          :
                          <span tw="mx-2">
                            {dateMonthYearFormatUtcToLocal(data.enrollmentDate)}
                          </span>
                        </Tbody>
                        <Thead scope="col">Last Due No</Thead>
                        <Tbody scope="col">
                          : <span tw="mx-2">{data.chitPaymentListSize}</span>
                        </Tbody>
                      </tr>
                      <tr>
                        <Thead scope="col">Cmplt Dt</Thead>
                        <Tbody scope="col">
                          :{" "}
                          <span tw="mx-2">
                            {dateMonthYearFormatUtcToLocal(
                              data.scheduleCompletionDate
                            )}
                          </span>
                        </Tbody>
                        <Thead scope="col">Total Paid </Thead>
                        <Tbody scope="col">
                          : <span tw="mx-2">{data.chitTotalPaymentMade}</span>
                        </Tbody>
                      </tr>
                      <tr>
                        <Thead scope="col">Duration</Thead>
                        <Tbody scope="col">
                          : <span tw="mx-2">{data.noOfMonths}</span>
                        </Tbody>
                        <Thead scope="col">Customer</Thead>
                        <Tbody scope="col">
                          : <span tw="sm:mx-2">{data.customerName}</span>
                        </Tbody>
                      </tr>
                    </tbody>
                  </table>
                  {/* {controllBtn(data.status) && (
                    <>
                      <button
                        tw="cursor-pointer h-10 px-4 xl:px-2 text-black transition-colors duration-200 rounded mx-2  hover:(text-white bg-custom-100)"
                        onClick={() => payment(data)}
                        className="border"
                      >
                        <i className="bi bi-eye mx-1"></i> View
                      </button>
                      <button
                        tw="cursor-pointer h-10 px-4 xl:px-2 text-white transition-colors duration-200  rounded mx-2 bg-custom-100  hover: hover:(text-black bg-white)"
                        className="border"
                        disabled={payLoading ? true : false}
                        onClick={() => OrderStatus(data)}
                      >
                        {payLoading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                            ></span>
                            <span className="mx-2">Loading . . .</span>
                          </>
                        ) : (
                          <>
                            <i className="bi bi-credit-card mx-1"></i> Pay Now
                          </>
                        )}
                      </button>
                    </>
                  )} */}
                </div>
              </div>
            </TabContent>
          ))
        )}
      </div>
      {/* ---- Modal --- */}
      {showModal ? (
        <PaymentHistory
          payDetails={PayHistory}
          close={close}
          currentChit={selectedScheme}
        />
      ) : (
        ""
      )}
    </div>
  );
};
