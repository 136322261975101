import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";

const createCustomer = (requestBody: string) => {
  return axios
    .post(`${BASE_URL}/customers/create`, requestBody, getHeaderConfig())
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const CustomerAutoSearch = (filters: String) => {
  return axios
    .get(`${BASE_URL}/customers/autosearch?${filters}&customerType=CUSTOMER`, getHeaderConfig())
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const updateCustomerDetails = (requestBody: Object) => {
  return axios
    .put(`${BASE_URL}/customers/update`, requestBody, getHeaderConfig())
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getCustomer = (id: String) => {
  return axios
    .get(`${BASE_URL}/customers/get?id=${id}`, getHeaderConfig())
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getUserAccount = (mobile: String) => {
  return axios
    .get(`${BASE_URL}/users/get-page?filters=${mobile}`, getHeaderConfig())
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const createUser = (requestBody: string) => {
  return axios
    .post(`${BASE_URL}/users/signup`, requestBody, getHeaderConfig())
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};



const pwdReset = (requestBody: string) => {
  return axios
    .put(`${BASE_URL}/users/resetpassword`, requestBody, getHeaderConfig())
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};



export {
  createCustomer,
  createUser,
  CustomerAutoSearch,
  updateCustomerDetails,
  getCustomer,
  getUserAccount,
  pwdReset
};
