import axios from 'axios';
import { BASE_URL } from '../utility/UrlConstants';
import { getHeaderConfig } from '../utility/HeaderConfig';


const getAllRole = (filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/admin/role/get-all?roleDetailFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};


export { getAllRole };
