import { getAllFinancialYear, getAllOrderStatus } from '../api/CheckOutApi';

const getDefaultFinancialYr = async () => {
  const filterData = {
    isDefault: 1
  };
  const responseData = getAllFinancialYear(filterData);
  responseData.then((data) => { });
  return responseData;
};

const getOrderStatusId = async (statusName) => {
  const filterData = {
    orderStatusName: statusName,
  };
  const responseData = getAllOrderStatus(filterData);
  responseData.then((data) => { });
  return responseData;
};

export {
  getDefaultFinancialYr,
  getOrderStatusId
};