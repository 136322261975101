import axios from "axios";
import { BASE_URL } from '../utility/UrlConstants';
import { getHeaderConfig } from '../utility/HeaderConfig';

const ProductDetails = (filters: String) => {
    return axios
      .get(`${BASE_URL}/products/get?id=${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  const likeCount = (filters: String) => {
    return axios
      .get(`${BASE_URL}/products-likes/product-likes-count?productId=${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  const wishCount = (filters: String) => {
    return axios
      .get(`${BASE_URL}/customer-wishlist/get-count-by-product?productId=${filters}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  export { ProductDetails , likeCount , wishCount }