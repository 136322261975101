import React, { memo, useEffect, useState } from "react";
import "twin.macro";
import { motion } from "framer-motion";
import success from "images/OrderStatus/success.gif";
import decline from "images/OrderStatus/decline.gif";
import { useCartContext } from "context/UseCartContext";
import {
  getAddToCart,
  getBranchId,
  getCorporateId,
  getCustomerId,
  getCustomerName,
  getOrderKeyId,
  setAddToCart,
} from "utility/HeaderConfig";
import {
  getPageOrderStatus,
  paygOrderStatusUpdate,
} from "api/OnlinePaymentApi";
import {  
  DIAMOND_PRODUCT_TYPE,
  COIN_NAME,
  COINS_NAME,
  SALES_ACCOUNTS,
  currentRegister,
} from "utility/Constant";
import {
  getAllPaymentMode,
  getAllExpenseCategory,
  createSales,
  getByLedgerName,
} from "../api/CheckOutApi";
import {
  getDefaultFinancialYr,
  getOrderStatusId,
} from "../utility/UtilFunction";
import { getAllDomainEmployeeDetails } from "api/EmployeeApi";
import { useHistory } from "react-router-dom";
import { getCustomer } from "api/CustomerApi";
import moment from "moment";

function OrderStatusPage() {
  const [OrderStatus, setOrderStatus] = useState("");
  const [transactionDetails, setTransactionDetails] = useState(""); 
  const [onlinePaymentEmplyId, setOnlinePaymentEmplyId] = useState("");
  const [invoiceExCategoryId, setInvoiceExCategoryId] = useState("");
  const [soldOrderStatusId, setSoldOrderStatusId] = useState("");
  const [saleOrderStatusId, setSaleOrderStatusId] = useState("");
  const [paymentModeList, setPaymentModeList] = useState([]);
  const [defaultFinalFinancialYr, setDefaultFinalFinancialYr] = useState("");
  const [ledgerNameDetails, setLedgerNameDetails] = useState({
    primaryGroupId: "",
    subGroupId: "",
    ledgerNameId: "",
    ledgerName: "",
  });
  const [Details, setDetails] = useState({
    name: "",
    mobile: "",
    email: "",
    address1: "",
    address2: "",
    pincode: "",
    landmark: "",
    districtName: "",
    stateName: "",
    countryName: "",
    customerId: "",
    customerCurrentCredit: 0,
    customerCurrentDebit: 0,
  });

  let history = useHistory();

  useEffect(() => {
    Promise.all([
      getCustomerDetails(),
      getPaymentModeList(),
      getOnlinePaymentEmployeeId(),
      getExpenseCategoryId(),
      getSoldStatusId(),
      getSaleStatusId(),
      getDefaultFinalcialYrId(),
      getLedgerNameDetails(),
      getPayOrderStatus(),
    ]);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (transactionDetails && OrderStatus) {
      updateOrderStatus();
    }
    //eslint-disable-next-line
  }, [OrderStatus]);

  const getCustomerDetails = () => {
    const response = getCustomer(getCustomerId());
    response.then((data) => {
      setDetails({
        customerId: data.response.id,
        name: data.response.name,
        mobile: data.response.mobile,
        email: data.response.email,
        address1: data.response.customerAddress.address1,
        address2: data.response.customerAddress.address2,
        pincode:
          data.response.customerAddress.pincodeNumber !== null
            ? data.response.customerAddress.pincodeNumber
            : "",
        landmark:
          data.response.customerAddress.landmark !== "-"
            ? data.response.customerAddress.landmark
            : "",
        districtName:
          data.response.customerAddress.districtName !== null
            ? data.response.customerAddress.districtName
            : "",
        stateName:
          data.response.customerAddress.stateName !== null
            ? data.response.customerAddress.stateName
            : "",
        countryName:
          data.response.customerAddress.countryName !== null
            ? data.response.customerAddress.countryName
            : "",
        customerCurrentDebit: data.response.debitValue,
        customerCurrentCredit: data.response.creditValue,
      });
    });
  };


  // getPayOrderStatus
  const getPayOrderStatus = async () => {
    const paygResData = await getPageOrderStatus(
      JSON.parse(getAddToCart())[0].branchName,
      JSON.parse(getOrderKeyId()).orderId
    );
    const response = paygResData.response;
    setTransactionDetails(response);
    if (response.PaymentResponseCode === 1) {
      setOrderStatus("Placed Successfully");
    } else if (response.PaymentResponseCode === 2) {
      setOrderStatus("Declined");
    } else if (response.PaymentResponseCode === 4) {
      setOrderStatus("Pending");
    } else if (response.PaymentResponseCode === 0) {
      setOrderStatus("");
    } else {
      setOrderStatus("");
    }
    return paygResData;
  };

  // UpdateOrderStatus
  const updateOrderStatus = () => {
    const saleOnlinePaymentUpdate = {
      branchId: getBranchId(),
      orderId: transactionDetails.OrderKeyId
        ? transactionDetails.OrderKeyId
        : null,
      customerId: getCustomerId(),
      customerName: getCustomerName(),
      amount: parseFloat(transactionDetails.UserDefinedData.UserDefined2),
      txnType: transactionDetails.PaymentMethod
        ? transactionDetails.PaymentMethod
        : null,
      currencyCode: "INR",
      txnId: transactionDetails.PaymentTransactionId
        ? transactionDetails.PaymentTransactionId
        : "",
      responseCode: transactionDetails.PaymentResponseCode,
      responseMessage: transactionDetails.paymentResponseText,
      status: OrderStatus,
      responseDatetime: currentRegister(
        moment(transactionDetails.PaymentDateTime, "DD-MM-YYYY hh:mm A").format(
          "YYYY-MM-DD HH:mm:ss"
        )
      ),
      paymentPurpose: "ECOMM_WEB_SALES",
      cardDetails: "[]",
      transactionReferenceNo: transactionDetails.PaymentTransactionRefNo,
      authorizedAmount: parseFloat(transactionDetails.OrderAmount),
      acceptTerms: "Yes",
      acceptTermsDatetime: currentRegister(
        transactionDetails.PaymentDateTime
      ),
      usageFrom: "WEB",
      customerEmail:
        transactionDetails.CustomerData &&
        transactionDetails.CustomerData.Email,
      customerPhone:
        transactionDetails.CustomerData &&
        transactionDetails.CustomerData.MobileNo,
      id: JSON.parse(getOrderKeyId()).statusId,
      active: 1,
    };

    paygOrderStatusUpdate(JSON.stringify(saleOnlinePaymentUpdate)).then(
      (data) => {
        if (data.response.responseCode === "1") {
          createSaleDetails();
        }
      }
    );
  };

  // Sale Create
  const getPaymentModeList = () => {
    const paymentModeRes = getAllPaymentMode();
    paymentModeRes.then((data) => {
      setPaymentModeList(data.response);
    });
  };

  const getOnlinePaymentEmployeeId = () => {
    const employeeFilterData = {
      name: "ONLINEPAYMENT",
    };
    const employeeData = getAllDomainEmployeeDetails(employeeFilterData);
    employeeData.then((data) => {
      let employeeIdResponse = data.response;
      setOnlinePaymentEmplyId(employeeIdResponse[0].id);
    });
  };

  const getExpenseCategoryId = () => {
    const filterData = {
      name: "invoice",
    };
    const responseData = getAllExpenseCategory(filterData);
    responseData.then((data) => {
      setInvoiceExCategoryId(data.response[0].id);
    });
  };

  const getDefaultFinalcialYrId = async () => {
    const finalcialYrResData = await getDefaultFinancialYr();
    if (finalcialYrResData && finalcialYrResData.length > 0)
      setDefaultFinalFinancialYr(finalcialYrResData[0].id);
  };

  const getSoldStatusId = async () => {
    const soldStatusResData = await getOrderStatusId("sold");
    if (soldStatusResData && soldStatusResData.response.length > 0)
      setSoldOrderStatusId(soldStatusResData.response[0].id);
  };

  const getSaleStatusId = async () => {
    const saleStatusResData = await getOrderStatusId("sale");
    if (saleStatusResData && saleStatusResData.response.length > 0)
      setSaleOrderStatusId(saleStatusResData.response[0].id);
  };

  const getLedgerNameDetails = async () => {
    const responseData = await getByLedgerName(SALES_ACCOUNTS);
    if (responseData && responseData.id !== undefined) {
      setLedgerNameDetails({
        primaryGroupId: responseData.metaLedgerPrimaryGroupId,
        subGroupId: responseData.metaLedgerSubGroupId,
        ledgerNameId: responseData.id,
        ledgerName: responseData.name,
      });
    }
    return responseData;
  };

  const createSaleDetails = () => {
    let viewCart = JSON.parse(getAddToCart()) && JSON.parse(getAddToCart());
    const saleItemsList = [];
    const taxJsonValue = [];
    const productDetailsJson = viewCart[0];
    productDetailsJson.saleTaxList.forEach((taxItem) => {
      taxJsonValue.push({
        [taxItem.name]: taxItem.value,
      });
    });

    const taxDetailsForReceipt = [];
    const paymentModeJson = [];
    const ledgerDetails = [];
    // let selectedPaymentMode = "";

    taxDetailsForReceipt.push({ tax: taxJsonValue });
    taxDetailsForReceipt.push({ module: "Sale" });

    const totalSaleAmtValue = viewCart.reduce((sum, i) => {
      return sum + i.total;
    }, 0);

    const filteredPaymodeData = [];
    const cashPaymodeData = paymentModeList.filter(
      (paymodeItemData) =>
        paymodeItemData.name && paymodeItemData.name.toLowerCase() === "cash"
    );

    paymentModeJson.push({
      tax: taxJsonValue,
      name:
        filteredPaymodeData.length > 0
          ? filteredPaymodeData[0].name
          : cashPaymodeData[0].name,
      value: totalSaleAmtValue.toFixed(2),
      remarks: `${totalSaleAmtValue.toFixed(2)} rcvd via app`,
      paymentType:
        filteredPaymodeData.length > 0
          ? filteredPaymodeData[0].id
          : cashPaymodeData[0].id,
    });

    // if (filteredPaymodeData.length > 0) {
    //   selectedPaymentMode = filteredPaymodeData[0].name;
    // }

    const totalSaleAmount = viewCart.reduce((sum, i) => {
      return sum + i.total;
    }, 0);

    if (parseFloat(totalSaleAmount) > 0) {
      ledgerDetails.push({
        accountEntryType: "DEBIT",
        activeDate: null,
        balanceAmount: parseFloat(totalSaleAmount),
        branchId: getBranchId(),
        corporateId: getCorporateId(),
        financialYear: defaultFinalFinancialYr,
        ledgerType: "RECEIVABLES",
        metaLedgerNameId: ledgerNameDetails.ledgerNameId,
        metaLedgerPrimaryGroupId: ledgerNameDetails.primaryGroupId,
        metaLedgerSubGroupId: ledgerNameDetails.subGroupId,
        name: "Sale Invoice",
        nextPaidDate: null,
        paidAmount: 0,
        paidDate: null,
        payableAmount: parseFloat(totalSaleAmount),
        paymentMode: JSON.stringify(paymentModeJson),
        refId: Details.customerId,
        refType: "CUSTOMER",
        refTypeName: Details.name,
        status: "ACTIVE",
        taxComponent: JSON.stringify(taxDetailsForReceipt),
        totalAmount: parseFloat(totalSaleAmount),
        txnRefType: "Sales_Invoice",
        orderNo: 1,
        id: null,
      });

      ledgerDetails.push({
        accountEntryType: "CREDIT",
        activeDate: null,
        balanceAmount: 0,
        branchId: getBranchId(),
        corporateId: getCorporateId(),
        financialYear: defaultFinalFinancialYr,
        ledgerType: "RECEIVABLES",
        metaLedgerNameId: ledgerNameDetails.ledgerNameId,
        metaLedgerPrimaryGroupId: ledgerNameDetails.primaryGroupId,
        metaLedgerSubGroupId: ledgerNameDetails.subGroupId,
        name: "Sale Invoice",
        nextPaidDate: null,
        paidAmount: parseFloat(totalSaleAmount),
        paidDate: null,
        payableAmount: parseFloat(totalSaleAmount),
        paymentMode: JSON.stringify(paymentModeJson),
        refId: Details.customerId,
        refType: "CUSTOMER",
        refTypeName: Details.name,
        status: "ACTIVE",
        taxComponent: JSON.stringify(taxDetailsForReceipt),
        totalAmount: parseFloat(totalSaleAmount),
        txnRefType: "Sales_Invoice",
        orderNo: 2,
        id: null,
      });
    }

    viewCart.forEach((item) => {
      console.log(item);
      const productFullDetails = item;
      const wastageType = productFullDetails.wastageType;
      const mcType = productFullDetails.mcType;
      const wastageValue = productFullDetails.wastageValue;
      const secMetalDetails = productFullDetails.secMetalDetails;
      const mcValue = productFullDetails.mcValue;
      const otherChargeData = JSON.parse(productFullDetails.otherCharges);

      let saleWastageType = "flat";
      let saleMCType = "flat";
      let saleWastageUnit = "percentage";
      let saleMCUnit = "rs";

      if (wastageType === "mg") {
        saleWastageUnit = "mg";
        saleWastageType = "pergm";
      } else if (wastageType === "rs") {
        saleWastageUnit = "rs";
        saleWastageType = "flat";
      } else if (wastageType === "percentage") {
        saleWastageUnit = "percentage";
        saleWastageType = "flat";
      } else if (wastageType === "pieces") {
        saleWastageUnit = "pieces";
        saleWastageType = "flat";
      }

      if (mcType === "mg") {
        saleMCType = "pergm";
        saleMCUnit = "rs";
      } else if (mcType === "rs") {
        saleMCType = "flat";
        saleMCUnit = "rs";
      } else if (mcType === "percentage") {
        saleMCType = "flat";
        saleMCUnit = "percentage";
      } else if (mcType === "pieces") {
        saleMCType = "flat";
        saleMCUnit = "pieces";
      }

      let mcGramType = "";
      let mcGramUnit = "";
      let mcRateType = "";
      let mcRateUnit = "";
      let saleMcAmount = 0;
      let saleMcWeight = 0;
      if (saleMCType === "pergm") {
        mcGramType = saleMCType;
        mcGramUnit = saleMCUnit;
        saleMcWeight = productFullDetails.mcValue;
      } else {
        mcRateType = saleMCType;
        mcRateUnit = saleMCUnit;
        saleMcAmount = productFullDetails.mcValue;
      }

      const productWeight = JSON.parse(productFullDetails.weight);
      const isCoinPresent = productFullDetails.productName.includes(
        COIN_NAME || COINS_NAME
      );
      const salesWeight = {
        grossWeight: productWeight.grossWeight,
        lessWeight: productWeight.lessWeight,
        netWeight: parseFloat(productWeight.netWeight).toString(),
        wastageValue: parseFloat(wastageValue).toString(),
        mcValue: parseFloat(mcValue).toString(),
        balanceLess: "0",
        balanceNet: "0",
        balanceGross: "0",
        manipulationData: {
          mcGramType,
          mcGramUnit,
          mcRateType,
          mcRateUnit,
          wastageType: saleWastageType,
          wastageUnit: saleWastageUnit,
          mcType: saleMCType,
          mcUnit: saleMCUnit,
        },
        isDiscountEnable: false,
        isSp: false,
      };

      let stoneChargeList = [];
      const stoneChargeDetails = [];
      const otherChargeDetails = [];

      otherChargeData.forEach((otherChargeItem) => {
        if ({}.hasOwnProperty.call(otherChargeItem, "stone")) {
          if (otherChargeItem.stone.length > 0) {
            stoneChargeList = otherChargeItem.stone;
          }
        }
      });

      stoneChargeList.forEach((stoneItem) => {
        let shape = "";
        let cutType = "";
        let clarity = "";
        let color = "";
        let purity = "";
        if (stoneItem.stoneDescription) {
          stoneItem.stoneDescription.forEach((stoneDescRes) => {
            if (stoneDescRes.key === "shape") {
              shape = stoneDescRes.value;
            } else if (stoneDescRes.key === "cut") {
              cutType = stoneDescRes.value;
            } else if (stoneDescRes.key === "clarity") {
              clarity = stoneDescRes.value;
            } else if (stoneDescRes.key === "color") {
              color = stoneDescRes.value;
            } else if (stoneDescRes.key === "purity") {
              purity = stoneDescRes.value;
            }
          });
        }

        const stoneDetailsArray = [];
        const stoneMetaPurityValue = {
          key: "purity",
          value: purity ? purity : "",
        };
        const stoneMetaColorValue = {
          key: "color",
          value: color ? color : "",
        };
        const stoneMetaClarityValue = {
          key: "clarity",
          value: clarity ? clarity : "",
        };
        const stoneMetaShapeValue = {
          key: "shape",
          value: clarity ? shape : "",
        };
        const stoneMetaCutUnCutValue = {
          key: "cut",
          value: cutType ? cutType : "",
        };
        stoneDetailsArray.push(
          stoneMetaPurityValue,
          stoneMetaColorValue,
          stoneMetaClarityValue,
          stoneMetaShapeValue,
          stoneMetaCutUnCutValue
        );
        stoneChargeDetails.push({
          grm: stoneItem.grm,
          cost:
            productFullDetails.pieceRate > 0 &&
            (stoneItem.dataName === undefined ||
              (stoneItem.dataName !== undefined &&
                stoneItem.dataName.toLowerCase() !== DIAMOND_PRODUCT_TYPE))
              ? 0
              : stoneItem.cost,
          remark: stoneItem.remark,
          typeId: stoneItem.typeId,
          typeName: stoneItem.typeName,
          rateType: stoneItem.rateType,
          pieces: stoneItem.pieces,
          size: stoneItem.size ? stoneItem.size : "",
          stoneDescription: stoneDetailsArray,
          materialType: stoneItem.materialType ? stoneItem.materialType : "",
        });
      });
      otherChargeDetails.push({ stone: stoneChargeDetails });

      otherChargeDetails.push({
        piece: 0,
        charge: productFullDetails ? productFullDetails.otherCharge : 0,
        type: "",
        remarks: "TotalOtherChargeAmt",
      });

      const secWeightDetails = [];
      const primaryTaxTotal = productFullDetails.saleTaxList.reduce(
        (sum, i) => {
          return sum + i.amount;
        },
        0
      );
      JSON.parse(secMetalDetails).forEach((secItem) => {
        let saleSecWastageType = "flat";
        let saleSecMCType = "flat";
        let saleSecWastageUnit = "percentage";
        let saleSecMCUnit = "rs";
        if (secItem.wastageType === "mg") {
          saleSecWastageUnit = "mg";
          saleSecWastageType = "pergm";
        } else if (secItem.wastageType === "rs") {
          saleSecWastageUnit = "rs";
          saleSecWastageType = "flat";
        } else if (secItem.wastageType === "percentage") {
          saleSecWastageUnit = "percentage";
          saleSecWastageType = "flat";
        } else if (secItem.wastageType === "pieces") {
          saleSecWastageUnit = "pieces";
          saleSecWastageType = "flat";
        }

        if (secItem.mcType === "mg") {
          saleSecMCType = "pergm";
          saleSecMCUnit = "rs";
        } else if (secItem.mcType === "rs") {
          saleSecMCType = "flat";
          saleSecMCUnit = "rs";
        } else if (secItem.mcType === "percentage") {
          saleSecMCType = "flat";
          saleSecMCUnit = "percentage";
        } else if (secItem.mcType === "pieces") {
          saleSecMCType = "flat";
          saleSecMCUnit = "pieces";
        }

        let secSaleMcWeight = null;
        let secSaleMcAmount = null;

        if (saleSecMCType === "pergm") {
          secSaleMcWeight = secItem.mcValue;
        } else {
          secSaleMcAmount = secItem.mcValue;
        }
        secWeightDetails.push({
          // secTax: secItem.tax,
          secTax: 0,
          weight: {
            netWeight: parseFloat(secItem.weight.netWeight).toString(),
            lessWeight: parseFloat(secItem.weight.lessWeight).toString(),
            grossWeight: parseFloat(secItem.weight.grossWeight).toString(),
          },
          secName: secItem.product_name,
          // secRate: secItem.price,
          secRate: 0,
          wastageValue: secItem.wastage,
          wastageUnit: saleSecWastageUnit,
          wastageType: saleSecWastageType,
          mcValue: secItem.mcValue,
          mcUnit: saleSecMCUnit,
          mcType: saleSecMCType,
          secPiece: 1,
          // secTotal: secItem.total,
          secTotal: 0,
          mc_weight: secSaleMcWeight,
          mc_amount: secSaleMcAmount,
          secBasePrice: 0,
          // secBasePrice: secItem.basePrice,
          secProductType: secItem.productType,
          secProductTypeName: secItem.productTypeName,
        });
      });
      saleItemsList.push({
        pieceRate: parseFloat(productFullDetails.pieceRate),
        pdtAdvItemId: null,
        additionalCharges: JSON.stringify(otherChargeDetails),
        basePrice: productFullDetails.basePrice,
        discount: 0,
        price: isCoinPresent
          ? productFullDetails.todayCoinRate
          : productFullDetails.todayRate,
        productId: productFullDetails.id,
        saleMcAmount,
        saleMcWeight,
        saleSecondaryWeight: JSON.stringify(secWeightDetails),
        saleWastage: item.wastageValue,
        salesWeight: JSON.stringify(salesWeight),
        soldBy: onlinePaymentEmplyId,
        status: soldOrderStatusId,
        tax: primaryTaxTotal.toFixed(2),
        total: item.total,
        estimation: null,
        piece: productFullDetails.piece,
        hasPartlySale: "No",
        // processStatus: pendingProcessStatusId,
        productTagNo: productFullDetails.productCode,
        productName: productFullDetails.productName,
        availableWeightAfterProcess: 0,
        processChangeDate: null,
        combinedProducts: null,
      });
    });

    const receiptData = [];
    receiptData.push({
      accountEntryType: "CREDIT",
      entryType: "RECEIVABLES",
      financialYear: defaultFinalFinancialYr,
      balanceAmount: 0,
      bankAccount: null,
      branch: getBranchId(),
      category: invoiceExCategoryId, // Need id
      customerId: Details.customerId,
      customerMobile: Details.mobile,
      customerName: Details.name,
      invoice: null,
      nextPaidDate: null,
      paidAmount: totalSaleAmount,
      payableAmount: totalSaleAmount,
      paymentMode: JSON.stringify(paymentModeJson),
      status: "paid",
      taxComponent: JSON.stringify(taxDetailsForReceipt),
      totalAmount: totalSaleAmount, // check with ganesh
      receiptType: "Sale_receipt",
      employeeId: onlinePaymentEmplyId,
      dayCloseModuleName: "sales",
    });
    const saleDetails = {
      branchId: getBranchId(),
      counterNo: null,
      exchangeAmount: 0,
      paymentMode: JSON.stringify(paymentModeJson),
      saleItems: saleItemsList,
      soldTo: {
        id: Details.customerId,
        mobile: Details.mobile,
        name: Details.name,
      },
      receiptList: receiptData,
      status: saleOrderStatusId, // Need to pass status id
      paymentType: "sale",
      totalAmt: totalSaleAmount,
      totalAmtWithoutDiscount: totalSaleAmount,
      discountAmt: 0,
      reference: "N/A",
      remarks: "",
      estimationId: "",
      exchange: null,
      estimationExchangeId: [],
      expenses: null,
      customerCreditDetails: null,
      chitDisbursement: null,
      estimation: [],
      returnDetail: JSON.stringify([]),
      receivedAmount: totalSaleAmount,
      returnChange: 0,
      customerCurrentCredit: 0, // Need to pass customer current credit value
      customerCurrentDebit: 0,
      custPrevCredit: Details.customerCurrentCredit, // need to check
      custPrevDebit: Details.customerCurrentDebit, // need to check
      custPayableAmount: 0,
      businessType: "B2C",
      tcsDetails: null,
      currencyCode: "INR", // check with nk sir
      ledgerDetailsDomainList: ledgerDetails,
      deleteLedgerDetailsIdsList: [],
    };

    console.log("saleDetails", saleDetails);
    const saleResponseData = createSales(saleDetails);
    saleResponseData
      .then((data) => {
        console.log(data);
        checkStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { dispatch } = useCartContext();
  const checkStatus = () => {
    setAddToCart(JSON.stringify([]));
    dispatch({ type: "ADD", payload: JSON.parse(getAddToCart()) });
  };

  return (
    <div className="bg-image">
      <motion.div
        initial={{ x: "700px", opacity: 0, scale: 0.5 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        tw="justify-center  flex overflow-x-hidden overflow-y-auto py-32 outline-none focus:outline-none "
      >
        <div tw="relative" className="col-12 col-md-7 col-lg-8 col-xl-10  ">
          <div
            className="box w-100"
            tw="shadow-2xl relative flex flex-col w-full  outline-none focus:outline-none "
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: `${
                OrderStatus.toLowerCase() === "success" ? "#f7f7f7" : "#fff"
              }`,
            }}
          >
            <div
              className="box-inner p-2"
              tw="flex-wrap sm:flex items-start justify-center items-center  border-solid border-custom-100 rounded-t"
            >
              <div className="text-center">
                <img
                  src={`${
                    OrderStatus.toLowerCase() === "success" ? success : decline
                  } `}
                  alt="Payment Success"
                  className="w-50 m-auto"
                />
                <h4
                  tw="text-2xl my-5 leading-8 uppercase tracking-wider"
                  className={`${
                    OrderStatus.toLowerCase() === "success"
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  Payment {OrderStatus} !
                </h4>
                <h5
                  tw="text-xl my-5 leading-8 uppercase tracking-wider"
                  className={`${
                    OrderStatus.toLowerCase() === "success"
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  Your Order Has Been {OrderStatus} !
                </h5>
                <h4 tw="text-base  text-yellow-700 leading-8 uppercase tracking-wider">
                  Order Id :{" "}
                  {transactionDetails !== ""
                    ? transactionDetails.OrderKeyId
                    : ""}
                </h4>
                <h4 tw="text-base  text-yellow-700 leading-8 uppercase tracking-wider">
                  Payment Transaction Id :{" "}
                  {transactionDetails !== ""
                    ? transactionDetails.PaymentTransactionId
                    : ""}
                </h4>
                <div className="pt-4 py-sm-3 d-flex justify-content-evenly">
                  <button
                    className="primary-btn cart-btns false hoverEffect py-2 px-3 px-sm-4 rounded-pill"
                    onClick={() => history.push("/ProductList")}
                  >
                    <span>Continue Shopping</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default memo(OrderStatusPage);
