import React, { memo } from "react";
import { dataList } from "api/SkeletonData";
import Skeleton from "react-loading-skeleton";

function SkeletonLoader() {
  return (
    <div className="row justify-content-center">
      {dataList.slice(0, 8).map((data , index) => (
        <div className="col-md-3 py-3" key={index}>
          <Skeleton tw="w-full" style={{ height: "200px" }} />
          <div className="card my-2 text-center py-2">
            <Skeleton className="px-3" tw="w-11/12" />
            <Skeleton className="px-3" tw="w-11/12" />
            <Skeleton className="px-3" tw="w-11/12" />
            <Skeleton className="px-3" tw="w-11/12" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default memo(SkeletonLoader);
