import React from 'react';
import  "twin.macro";

export default function ProductPriceDetails({Details}) {
  return (
      <>
        <h6 tw="text-custom-100  mb-5">Price Details :</h6>
        <table className="table table-bordered text-uppercase m-0">
          <tbody>
            <tr>
              <td><div className="d-flex justify-content-between"><small className="w-50">{Details.metalType}</small> <small className="w-50 text-end">₹ {(Details.basePrice - Details.wastageCost - Details.makingCost - Details.otherCharge).toFixed(2)}</small></div></td>
              <td rowspan={`${Details.gemTotal && Details.gemTotal !== 0 && Details.diamondTotal && Details.diamondTotal !== 0  ? '3' : '2'}`}><div className="d-flex justify-content-between align-items-center w-100" style={{height: Details.gemTotal && Details.gemTotal !== 0 && Details.diamondTotal && Details.diamondTotal !== 0  ? "95px" : '60px'}}><small className="">{Details.saleTaxList && Details.saleTaxList[1].name + ` -    ${Details.saleTaxList[1].value }`}</small> <small className="w-50 text-end">₹ {Details.saleTaxList && Details.saleTaxList[1].amount}</small></div></td>                                
            </tr>
            {Details.gemTotal && Details.gemTotal !== 0 ?
            <tr>
                <td><div className="d-flex justify-content-between"><small className="w-50">Stone</small> <small className="w-50 text-end">₹ {Details.gemTotal.toFixed(2)}</small></div></td>
            </tr>
            : ''
            }
             {Details.diamondTotal && Details.diamondTotal !== 0 ?
            <tr>
                <td><div className="d-flex justify-content-between"><small className="w-50">Diamond </small> <small className="w-50 text-end">₹ {Details.diamondTotal.toFixed(2)}</small></div></td>
            </tr>
            :
            ''
            }
            <tr>
              <td><div className="d-flex justify-content-between"><small className="w-50">Wastage</small> <small className="w-50 text-end">{Details.wastageCost && Details.wastageInPer} %</small></div></td>
               {Details.gemTotal || Details.diamondTotal ? <td  rowspan={`${Details.gemTotal || Details.diamondTotal ? '2' : ''}`}><div className="d-flex justify-content-between align-items-center w-100" style={{height: Details.gemTotal || Details.diamondTotal ? "60px" : ''}}><small className="w-50">{Details.saleTaxList && Details.saleTaxList[0].name + ` -  ${Details.saleTaxList[0].value }`}</small> <small className="w-50 text-end">₹ {Details.saleTaxList && Details.saleTaxList[0].amount}</small></div></td> : ''}
            </tr>
            <tr>
              <td><div className="d-flex justify-content-between"><small className="w-50">M.C</small> <small className="w-50 text-end">₹ {Details.makingCost && Details.makingCost.toFixed(2)}</small></div></td>
              {Details.gemTotal || Details.diamondTotal ? '': <td  rowspan={`${Details.gemTotal || Details.diamondTotal ? '2' : ''}`}><div className="d-flex justify-content-between align-items-center w-100" style={{height: Details.gemTotal || Details.diamondTotal ? "60px" : ''}}><small className="w-50">{Details.saleTaxList && Details.saleTaxList[0].name + ` -  ${Details.saleTaxList[0].value }`}</small> <small className="w-50 text-end">₹ {Details.saleTaxList && Details.saleTaxList[0].amount}</small></div></td> }
            </tr>
        {Details.hallmarkCharge !==0 && (
          <tr>
          <td><div className="d-flex justify-content-between"><small className="w-50">Hallmark</small> <small className="w-50 text-end">₹ {Details.hallmarkCharge &&  (Details.hallmarkCharge).toFixed(2)}</small></div></td>
          <td><div className="d-flex justify-content-between"><small className="w-50">H/M Tax</small> <small className="w-50 text-end">{Details.hmTaxPercentage && Details.hmTaxPercentage} %</small></div></td>
          </tr>)
        }
         
            <tr>
            <td><div className="d-flex justify-content-between"><small className="w-50">Sub-Total</small> <small className="w-50 text-end">₹ {Details.BasePrice &&  (Details.BasePrice).toFixed(2)}</small></div></td>
            <td><div className="d-flex justify-content-between"><small className="w-50">Total</small> <small className="w-50 text-end">₹ {Details.total && Details.total.toFixed(2)}</small></div></td>
            </tr>
          </tbody>
        </table>
      {Details.otherCharge > 0 ? <span className="text-danger" tw="text-sm">* Note Other Charges ( {Details.otherCharge.toFixed(2)} ) added to SUB-TOTAL</span> : '' }
</>
  )
}
