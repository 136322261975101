import React, { memo, useEffect, useState } from "react";
import LoginModal from "./LoginModal";
import ReginsterModal from "./RegisterModal";

function Modal({ register }) {
  const [split, setSplit] = useState(true);

  useEffect(() => {
    if (register === "register") {
      setSplit(false);
    }
    //eslint-disable-next-line
  }, []);

  console.log(register);

  // console.log(register);

  const divider = (condition) => {
    if (condition) {
      setSplit(true);
    } else {
      setSplit(false);
    }
  };
  return (
    <>
      {split ? (
        <LoginModal divider={divider} />
      ) : (
        <ReginsterModal divider={divider} />
      )}
    </>
  );
}

export default memo(Modal);
