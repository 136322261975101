import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";

const getPageOrderStatus = (brachName , OrderId) => {
  return axios
    .get(
      `${BASE_URL}/accounts/onlinePayment/get-payg-order-status?branchName=${brachName}&orderDetails=${OrderId}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};


const paygOrderStatus = (requestBody: string) => {
    return axios
      .post(`${BASE_URL}/accounts/onlinePayment/create-payg-order`, requestBody, getHeaderConfig())
      .then((response) =>  {
          const respData = {
            responseCode: response.status,
            response: response.data
          };
          return respData;
        })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };


  const paygOrderStatusUpdate = (requestBody: string) => {
    return axios
      .put(`${BASE_URL}/accounts/onlinePayment/update`, requestBody, getHeaderConfig())
      .then((response) =>  {
          const respData = {
            responseCode: response.status,
            response: response.data
          };
          return respData;
        })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };


export {
    paygOrderStatus,
    getPageOrderStatus,
    paygOrderStatusUpdate
};
