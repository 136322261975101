import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";


const branchdetails = (id: String) => {
  return axios
    .get(
      `${BASE_URL}/admin/corporate/branchdetails/get?id=${id}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};


const getAllBranch = (filters) => {
  return axios
    .get(
      `${BASE_URL}/admin/corporate/branchdetails/get-page?page=0&size=50&sort=createdAt,desc&branchDetailsFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};


export { branchdetails , getAllBranch};
