export const dataList =[
  {
    name: "Xyz Jewellery",
    address: "Xyz Address Stream Tracmail House, A Senapati Bapat Marg, Delisle Road.",
    pincode: "Salem - 606010",
    Tel: "099522 10099"
  },
  {
    name: "Xyz Jewellery",
    address: "Xyz Address Stream Tracmail House, A Senapati Bapat Marg, Delisle Road.",
    pincode: "Salem - 606010",
    Tel: "099522 10099"
  },
  {
    name: "Xyz Jewellery",
    address: "Xyz Address Stream Tracmail House, A Senapati Bapat Marg, Delisle Road.",
    pincode: "Salem - 606010",
    Tel: "099522 10099"
  },
  {
    name: "Xyz Jewellery",
    address: "Xyz Address Stream Tracmail House, A Senapati Bapat Marg, Delisle Road.",
    pincode: "Salem - 606010",
    Tel: "099522 10099"
  },
  {
    name: "Xyz Jewellery",
    address: "Xyz Address Stream Tracmail House, A Senapati Bapat Marg, Delisle Road.",
    pincode: "Salem - 606010",
    Tel: "099522 10099"
  },
  {
    name: "Xyz Jewellery",
    address: "Xyz Address Stream Tracmail House, A Senapati Bapat Marg, Delisle Road.",
    pincode: "Salem - 606010",
    Tel: "099522 10099"
  },
  {
    name: "Xyz Jewellery",
    address: "Xyz Address Stream Tracmail House, A Senapati Bapat Marg, Delisle Road.",
    pincode: "Salem - 606010",
    Tel: "099522 10099"
  },
  {
    name: "Xyz Jewellery",
    address: "Xyz Address Stream Tracmail House, A Senapati Bapat Marg, Delisle Road.",
    pincode: "Salem - 606010",
    Tel: "099522 10099"
  }
]