import { useContext } from 'react';
import { CartContext } from 'context/CartContext';

export const useCartContext = () => {
    
    const authContext = useContext(CartContext)

    if (!authContext) {
        throw new Error('CartContext is undefined')
    }
    return authContext
}