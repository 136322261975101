import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";



const Language = () => {
    return axios
      .get(`${BASE_URL}/admin/metadata/language/get-all`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  const  EnquierySource = () => {
    return axios
      .get(`${BASE_URL}/crm/ems/enquiry/meta/enquirysource/get-all`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  const  EnquieryStatus = () => {
    return axios
      .get(`${BASE_URL}/crm/ems/enquiry/meta/enquirystatus/get-all`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  const  EmplyeeId = (employeeFilter) => {
    return axios
      .get(`${BASE_URL}/hr/employeedetails/get-all-domain?${employeeFilter}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };

  const  EnquieryTypeId = () => {
    return axios
      .get(`${BASE_URL}/crm/ems/enquiry/meta/enquirytype/get-all`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };
  

  const  createEnquiry = (request) => {
    return axios
      .post(`${BASE_URL}/crm/ems/enquiry/create`, request, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };


  const  getEnquiryByPage = (filterValue) => {
    return axios
      .get(`${BASE_URL}/crm/ems/enquiry/get-page?page=0&size=10&enquiryDetailsFilter=${filterValue}`, getHeaderConfig())
      .then(response => {
        const respData = {
          responseCode: response.status,
          response: response.data
        };
        return respData;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };
  
  
  

  export { Language , EnquierySource , EnquieryStatus , EmplyeeId , EnquieryTypeId , createEnquiry , getEnquiryByPage};

