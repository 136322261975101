import React, { memo } from "react";
import  "twin.macro";

function ChoosePaymentMethod({Loading , OrderStatus}) {
  return (
    <form className="needs-validation">
      <div className="row">
        <h5>Choose Your Payment Method</h5>
        <div
          className="form-check"
          tw="sm:mx-20 sm:my-10 m-5"
          // style={{ margin: " 30px 51px" }}
        >
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            checked
          />
          <label className="form-check-label " htmlFor="flexRadioDefault1">
            Credit Card, Debit Card, NetBanking E Wallet etc..
          </label>
        </div>
        <div className="form-check py-2 " tw="text-sm">
          <input
            className="form-check-input mx-sm-2 mx-sm-1"
            type="checkbox"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            checked
          />
          <label
            className="form-check-label text-xs"
            htmlFor="flexRadioDefault1"
          >
            I Agree with the Terms & Conditions
          </label>
        </div>
        <hr className="mb-3" />
        <div className="text-center">
          <button
            className="btn-md hoverEffect px-3 py-2 rounded "
            onClick={OrderStatus}
            disabled={Loading ? true : ""}
            type="button"
          >
            {Loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
                <span className="mx-2">Loading . . .</span>
              </>
            ) : (
              <>
                <i
                  className="bi bi-credit-card-2-front"
                  tw="px-3 text-base"
                ></i>
                Proceed to Pay
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
}

export default memo(ChoosePaymentMethod)