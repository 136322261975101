import axios from "axios";
import { BASE_URL } from "../utility/UrlConstants";
import { getHeaderConfig } from "../utility/HeaderConfig";


const pincodeAutoSearch = (searchValue: string) => {
  return axios
    .get(`${BASE_URL}/admin/address/pincode/autosearch-code?searchString=${searchValue}`, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      throw error;
    });
};

const getPincodeHierarchy = (id: string) => {
  return axios
    .get(
      `${BASE_URL}/admin/address/pincode/get-hierarchy?id=${id}`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

  export {pincodeAutoSearch , getPincodeHierarchy};