import "twin.macro";
import { memo, useEffect, useState } from "react";
import TopbarMenus from "./TopbarMenus";
import { ECommerce } from "api/SocialMediaConfigApi";

function Topbar({ branch }) {
  const [media, setMedia] = useState([]);

  useEffect(() => {
    socialMedia();
  }, []);

  const socialMedia = () => {
    let filter = "social_media_config";
    ECommerce(filter).then((data) => {
      let SOCIALMEDIA = JSON.parse(data.response.moduleField);
      setMedia(SOCIALMEDIA);
    });
  };

  return (
    <div tw=" text-black bg-custom-200">
      <div tw="max-w-full px-4 sm:px-6 lg:px-8 m-auto flex items-center justify-center sm:justify-between h-10">
        <div tw="xl:flex-1 lg:flex-grow-0 lg:inline-flex hidden items-center">
          <i className="bi bi-whatsapp" tw="text-xl font-semibold"></i>
          <span tw="lg:text-xs xl:text-sm font-normal ml-1">
            Need Help? call us: {branch.mobile}
          </span>
        </div>
        <div tw="hidden sm:flex text-center">
          {media.map((data , index) => (
            <a href={data.value} tw="text-black" target="blank" key={index}>
              <i
                className={`bi bi-${data.key?.toLowerCase()}`}
                tw="text-base mx-2 md:mx-4  hover:text-rose-500 cursor-pointer"
              ></i>
            </a>
          ))}
        </div>
        <TopbarMenus />
      </div>
    </div>
  );
}

export default memo(Topbar);
