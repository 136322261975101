import React, { memo, useEffect, useState } from "react";
import Bangle from "images/MainpageBanners/bangles.png";
import Carousel from "react-elastic-carousel";
import mostLikedImage from "images/MainpageBanners/mostLiked.png";
import tw from "twin.macro";
import styled from "styled-components";
import Noimg from "../images/no_img.jpg";
import { motion } from "framer-motion";



import { useHistory } from "react-router-dom";
import { mostLiked } from "api/MostLikedApi";

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:(mr-2 my-2) last:mr-0 text-black font-medium rounded-sm transition duration-300 text-xs sm:text-sm w-1/2 sm:w-auto text-center uppercase border border-gray-400 border-solid`}
  &:hover {
    ${tw`bg-custom-100 text-white`}
  }
  ${(props) => props.active && tw`bg-custom-100! text-gray-100!`}
  }
`;

function MostedLikedComponent() {
  const [categoryList, setCategoryList] = useState([]);

  const history = useHistory();

  useEffect(() => {
    getMostLiked();
  }, []);

  const getMostLiked = () => {
    mostLiked({}).then((data) => {
      const saleProduct = [];
      data.response.forEach((data) => {
        if (data.statusName.toLowerCase() === "sale") {
          saleProduct.push(data);
        }
      });
      setCategoryList(saleProduct);
    });
  };

  return (
    <motion.div
    className="row"
    initial={{ x: "50px", opacity: 0, scale: 0.5 }}
    animate={{ x: 0, opacity: 1, scale: 1 }}
  >
      <div className="col-md-6">
        <img
          src={mostLikedImage}
          alt="New Arrivals"
          className="w-100 rounded"
          tw="cursor-pointer"
          onClick={() => history.push("MostLiked")}
        />
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-6 text-center">
            <h1 className="fw-bold" tw="md:m-0 my-5">
              Most Liked Product
            </h1>
            <p className=" ptSans">
              Our most loved designs deserve a worthy mention and here they are!
            </p>
            <TabControl
              key={1}
              active={false}
              onClick={() => history.push("MostLiked")}
              className="m-auto fw-bold w-75 text-center"
              tw="rounded-lg"
            >
              View All
            </TabControl>
          </div>
          <div className="col-md-6">
            <img
              src={Bangle}
              alt="New Arrivals"
              className="w-100"
              tw="rounded"
            />
          </div>
        </div>
        <Carousel
          enableAutoPlay
          autoPlaySpeed={1500} // same time
          itemsToShow={2}
          itemPadding={[5, 5]}
          className="newArrivalsComponent align-items-center pt-4"
        >
          {categoryList.slice(0, 8).map((data ,index) => (
            <img
              src={
                data.productImageUrls[0] == null
                  ? Noimg
                  : data.productImageUrls[0].imageUrl
              }
              alt="New Arrivals"
              className="w-100 rounded"
              tw="cursor-pointer  h-48"
              onClick={() => history.push("MostLiked")}
              key={index}
            />
          ))}
        </Carousel>
      </div>
    </motion.div>
  );
}

export default memo(MostedLikedComponent);
