import React, { memo, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import MostedLikedComponent from "./MostedLikedComponent";
import NewArrivalsComponent from "./NewArrivalsComponent";

const HeaderRow = tw.div`flex justify-center items-center flex-col xl:flex-row`;
const TabsControl = tw.div`flex flex-wrap bg-custom-200 p-3 rounded leading-none my-8 `;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mx-0 sm:mx-1 text-black font-medium rounded-sm transition duration-300 text-xs sm:text-sm w-1/2 sm:w-auto text-center uppercase border border-custom-100 border-dashed`}
  &:hover {
    ${tw`bg-custom-100 text-white`}
  }
  ${(props) => props.active && tw`bg-custom-100! text-gray-100!`}
  }
`;

const menu = [
  {
    name: "New Arrivals",
  },
  {
    name: "Most Liked Product",
  },
];

function ArrivalsLiked() {
  const [name, setName] = useState("New Arrivals");

  const getCategoryList = (data) => {
    setName(data);
  };
  return (
    <div className="container pt-4 pb-sm-5 pt-sm-0">
        <HeaderRow className="container-fluid justify-content-end">
          <TabsControl>
            {menu.map((data , index) => (
              <TabControl
                key={index}
                active={data.name === name}
                onClick={() => getCategoryList(data.name)}
                className="ptSans"
              >
                {data.name}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>
        {name === "New Arrivals" ? (
          <NewArrivalsComponent />
        ) : (
          <MostedLikedComponent />
        )}
      </div>
  );
}

export default memo(ArrivalsLiked);
