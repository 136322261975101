import axios from 'axios';
import { BASE_URL } from '../utility/UrlConstants';
import { getHeaderConfig } from '../utility/HeaderConfig';


const getProduct = (size , filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/products/get-product?page=0&size=${size}&sort=createdAt,desc&productFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const orderStatus = (filters: Object) => {
  return axios
    .get(`${BASE_URL}/admin/others/orderstatus/get-all?filters=${JSON.stringify(
      filters
    )}`, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};


export { getProduct , orderStatus};
